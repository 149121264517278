import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import ArgonAvatar from "components/ArgonAvatar";
import { useNavigate } from "react-router-dom";

function AccountantPayroll() {
  const [apiWards, setApiWards] = useState([])
  const [apiWardsSearch, setApiWardsSearch] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const completeRowsData = apiWardsSearch.length > 0 ? apiWardsSearch : apiWards

  const UsersData = {
    columns: [
      // { name: "user_id", align: "left" },
      { name: "name", align: "left" },
      { name: "status", align: "left" },
      { name: "region", align: "left" },
      { name: "salary_of", align: "left" },
      { name: "pay_date", align: "center" },
      { name: "salary_slip", align: "left" },
    ],

    rows: completeRowsData.map((item, idx) => {
      const data = {
        // user_id: <p key={idx} style={{ padding: 10 }}>{item.id}</p>,
        name: <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
          <ArgonBox mr={2}>
            <ArgonAvatar src={item.photo} alt={`${item.first_name} ${item.middle_name} ${item.last_name}`} size="sm" variant="rounded" />
          </ArgonBox>
          <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography variant="button" fontWeight="medium">
              {`${item.first_name} ${item.middle_name} ${item.last_name}`}
            </ArgonTypography>
            <ArgonTypography variant="caption" color="secondary">
              {item.email}
              <br />
              {item.phone_number}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>,
        status: <ArgonBox display="flex" flexDirection="column">
          <ArgonButton style={{ fontSize: 10, padding: 5 }} color={item.pay_status === "PAID" ? "secondary" : "warning"} size="small">
            {item.pay_status}
          </ArgonButton>
        </ArgonBox>,
        region: item.region,
        salary_of: <p>{item.pay_month} {item.pay_year}</p>,
        pay_date: item.created_at,

        salary_slip: (
          <>
            <div>
              <p>
                <strong>Salary: TZS {Intl.NumberFormat().format(item.salary)}/=</strong>
                <br />
                <strong>Commission: TZS {Intl.NumberFormat().format(item.commission)}/=</strong>
              </p>
              <hr style={{ opacity: 0.2 }} />
              {item.salary_consumers.map((cons, id) =>
                <div key={id}>
                  <p>{cons.consumer}: TZS {Intl.NumberFormat().format(cons.amount)}/=</p>
                  <hr style={{ opacity: 0.2 }} />
                </div>
              )}
              <p><strong>Payable: TZS {Intl.NumberFormat().format(item.remain_salary + item.commission)}/=</strong></p>
            </div>
          </>
        ),
      }
      return data
    }),
  };

  const { columns, rows } = UsersData;


  const getApiFirstData = () => {
    const url = `${API.url}get-all-payroll-history-from-accountant?page=1&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Payroll_accountant_data_async", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Payroll_accountant_data_async")
        setApiWards(JSON.parse(finalData))
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const getApiData = () => {
    const startData = localStorage.getItem("Payroll_accountant_data_async")
    if (startData) {
      setApiWards(JSON.parse(startData))
      setApiLoading(false)
      setEndLoading(false)
    }

    const url = `${API.url}get-all-payroll-history-from-accountant?page=${page}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Payroll_accountant_data_async", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Payroll_accountant_data_async")
        setApiWards(JSON.parse(finalData))

        setApiLoading(false)
        setEndLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, [page]);

  const searchUsersFunction = (search) => {
    const url = `${API.url}get-all-payroll-history-from-accountant-by-search?search=${encode(search)}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiWardsSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const submitPayrollToCEO = () => {
    swal({
      title: "Hold on!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          setApiLoading(true)
          const url = `${API.url}submit-payroll-from-accountant-to-ceo?key=${API.key}`
          fetch(url)
            .then(res => res.json())
            .then(data => {
              swal(data.result, data.message, data.icon);
              getApiFirstData()
              setApiLoading(false)
            })
            .catch(err => {
              setApiLoading(false)
              swal("Opps!", err.message, "warning");
            })
        } else {
          swal("Action cancelled!");
        }
      });
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">PayRoll History Table | Page {page}</ArgonTypography>
                </ArgonBox>

                <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search Here..."
                    onChange={(e) => {
                      searchUsersFunction(e.target.value)
                    }}
                  />
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </ArgonBox>
                <ArgonBox display="flex" p={3}>
                  <ArgonButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        if (page <= 1) {
                          setPage(1)
                          setEndLoading(false)
                        } else {
                          setPage(page - 1)
                        }
                      }, 1000);
                    }}
                  >
                    &laquo; prev
                  </ArgonButton>

                  <ArgonButton
                    style={{ marginLeft: 10 }}
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        setPage(page + 1)
                      }, 1000);
                    }}
                  >
                    next &raquo;
                  </ArgonButton>
                </ArgonBox>
                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
                <hr />
                {completeRowsData && completeRowsData.length > 0 && (
                  <ArgonBox>
                    <br />
                    <ArgonButton onClick={() => submitPayrollToCEO()} color="warning">Submit to CEO &raquo;</ArgonButton>
                  </ArgonBox>
                )}
              </Card>
            </ArgonBox>
          </ArgonBox>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default AccountantPayroll;