import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import ArgonAvatar from "components/ArgonAvatar";
import { SelectStyle } from "constants/SelectStyle";
import { months } from "constants/StaticDatas";
import { useNavigate } from "react-router-dom";

function PayRoll() {
  const [isLoading, setIsLoading] = useState(false)
  const [apiUsers, setApiUsers] = useState([])
  const [apiWardsSearch, setApiWardsSearch] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);
  const currentYear = new Date().getFullYear()
  const [monthToPay, setMonthToPay] = useState("")
  const [yearToPay, setYearToPay] = useState(currentYear)
  const [messageBody, setMessageBody] = useState([])
  const [checkedItems, setCheckedItems] = useState({});

  const handleChange = (event, item) => {
    const updatedCheckedItems = {
      ...checkedItems,
      [item.id]: event.target.checked,
    };
    setCheckedItems(updatedCheckedItems);
  };

  const handleScroll = (event) => {
    for (const item in checkedItems) {
      if (checkedItems[item]) {
        event.preventDefault();
        break;
      }
    }
  };

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const completeRowsData = apiWardsSearch.length > 0 ? apiWardsSearch : apiUsers

  const UsersData = {
    columns: [
      { name: "name", align: "left" },
      { name: "status", align: "left" },
      { name: "salary", align: "left" },
      { name: "action", align: "center" },
    ],
    rows: completeRowsData.map((item) => {
      return {
        name: (
          <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
            <ArgonBox mr={2}>
              <ArgonAvatar src={item.photo} alt={`${item.first_name} ${item.middle_name} ${item.last_name}`} size="sm" variant="rounded" />
            </ArgonBox>
            <ArgonBox display="flex" flexDirection="column">
              <ArgonTypography variant="button" fontWeight="medium">
                {`${item.first_name} ${item.middle_name} ${item.last_name}`}
              </ArgonTypography>
              <ArgonTypography variant="caption" color="secondary">
                {item.email}
                <br />
                {item.phone_number}
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
        ),
        status: (
          <ArgonBox display="flex" flexDirection="column">
            <ArgonButton style={{ fontSize: 10, padding: 5 }} color={item.status === "AVAILABLE" ? "secondary" : "warning"} size="small">
              {item.status}
            </ArgonButton>
          </ArgonBox>
        ),
        salary: (
          <div>
            <p>
              <strong>Salary: TZS {Intl.NumberFormat().format(item.salary)}/=</strong>
              <br />
              <strong>Commission: TZS {Intl.NumberFormat().format(item.commission)}/=</strong>
            </p>
            <hr style={{ opacity: 0.2 }} />
            {item.salary_consumers.map((cons, id) =>
              <div key={id}>
                <p>{cons.consumer}: TZS {Intl.NumberFormat().format(cons.amount)}/=</p>
                <hr style={{ opacity: 0.2 }} />
              </div>
            )}
            <p><strong>Payable: TZS {Intl.NumberFormat().format(item.remain_salary + item.commission)}/=</strong></p>
          </div>
        ),
        action: (
          <label style={{ cursor: "pointer" }}>
            <input
              checked={checkedItems[item.id] || false}
              onChange={(event) => handleChange(event, item)}
              className="selectedUser" id={item.id} name={`${item.first_name} ${item.middle_name} ${item.last_name}`} value={item.salary} type="checkbox" step={item.commission} style={{ cursor: "pointer" }} />
          </label>
        ),
      };
    }),
  };

  const { columns, rows } = UsersData;

  const payUsersSalary = () => {
    if (monthToPay === "" || yearToPay === "") {
      swal("Opps!", "Please fill all required information", "warning")
      return
    }
    const users = document.querySelectorAll(".selectedUser")
    const selectedData = []
    users.forEach(d => {
      if (d.checked === true) {
        selectedData.push(d)
      }
    });

    const bodyMessage = []
    if (selectedData.length > 0) {
      setIsLoading(true)
      selectedData.forEach(item => {
        const formData = new FormData()
        formData.append("user_id", encode(item.id))
        formData.append("pay_month", encode(monthToPay))
        formData.append("pay_year", encode(yearToPay))
        formData.append("amount", encode(item.value))
        formData.append("commission", encode(item.step))
        formData.append("registered_by", encode(localStorage.getItem("user_id")))
        fetch(`${API.url}request-to-pay-user-salary?key=${API.key}`, { method: "POST", body: formData })
          .then(res => res.json())
          .then(res => {
            bodyMessage.push(res)
          })
          .catch(err => {
            setIsLoading(false)
            swal("Opps!", err.message, "warning");
          })
      });
      setTimeout(() => {
        setIsLoading(false)
        setMessageBody(bodyMessage)
        setCheckedItems({})
        swal("SUCCESS", "Your request is fully successfully", "success");
      }, 3000);
    } else {
      swal("Opps!", "Please select users to pay", "warning");
    }
  }

  const getApiData = () => {
    const startData = localStorage.getItem("Users_data_async")
    if (startData) {
      setApiUsers(JSON.parse(startData))
      setApiLoading(false)
      setEndLoading(false)
    }

    const url = `${API.url}get-all-users?page=${page}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Users_data_async", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Users_data_async")
        setApiUsers(JSON.parse(finalData))

        setApiLoading(false)
        setEndLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, [page]);

  const searchUsersFunction = (search) => {
    const url = `${API.url}get-all-users-by-search?search=${encode(search)}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiWardsSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout onScroll={handleScroll}>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">PayRoll Table | Page {page}</ArgonTypography>
                </ArgonBox>

                <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search Here..."
                    onChange={(e) => {
                      searchUsersFunction(e.target.value)
                    }}
                  />
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <ArgonBox display="flex" justifyContent="space-between">
                    <div style={{ width: "60%" }}>
                      <Table columns={columns} rows={rows} />
                    </div>
                    <div style={{ width: "39%" }}>
                      {isLoading === true ? (
                        <Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />
                      ) : (
                        <ArgonBox p={3}>
                          <select value={monthToPay} onClick={(e) => setMonthToPay(e.target.value)} onChange={(e) => setMonthToPay(e.target.value)} style={SelectStyle}>
                            <option>--Select Month--</option>
                            {months.map((item, idx) =>
                              <option key={idx} value={item.key}>{item.name}</option>
                            )}
                          </select>

                          <ArgonInput style={{ marginTop: 6 }} onChange={(e) => setYearToPay(e.target.value)} value={yearToPay} placeholder="Enter Year" />

                          <ArgonButton style={{ marginTop: 6 }} onClick={() => payUsersSalary()} color="primary">Request to Pay</ArgonButton>

                          <div id="messageBody" style={{ width: "100%" }}>
                            <br />
                            {messageBody.map((item, idx) =>
                              <ArgonButton style={{ marginBottom: 6 }} key={idx} color={item.icon} fullWidth>{item.message}</ArgonButton>
                            )}
                          </div>
                        </ArgonBox>
                      )}
                    </div>
                  </ArgonBox>
                </ArgonBox>
                <ArgonBox display="flex" p={3}>
                  <ArgonButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        if (page <= 1) {
                          setPage(1)
                          setEndLoading(false)
                        } else {
                          setPage(page - 1)
                        }
                      }, 1000);
                    }}
                  >
                    &laquo; prev
                  </ArgonButton>

                  <ArgonButton
                    style={{ marginLeft: 10 }}
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        setPage(page + 1)
                      }, 1000);
                    }}
                  >
                    next &raquo;
                  </ArgonButton>
                </ArgonBox>
                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
              </Card>
            </ArgonBox>
          </ArgonBox>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default PayRoll;
