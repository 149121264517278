import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
// Argon Dashboard 2 MUI base styles

// Dashboard layout components

// Data
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay, faHandHoldingDroplet, faHistory, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import API from "Api/Api";
import ArgonTypography from "components/ArgonTypography";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import MainLoading from "Helpers/MainLoading";
import swal from "sweetalert";

function InventoryDashboard() {
  const [products, setProducts] = useState([])
  const [materials, setMaterials] = useState([])

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const getProductsData = () => {
    const startData = localStorage.getItem("products_data_async")
    if (startData) {
      setProducts(JSON.parse(startData))
    }

    const url = `${API.url}get-all-products?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("products_data_async", JSON.stringify(data.data))
        const finalData = localStorage.getItem("products_data_async")
        setProducts(JSON.parse(finalData))
      })
      .catch(err => {
        swal("Opps!", err.message, "warning");
      })
  }

  const getProductionMaterials = () => {
    const startData = localStorage.getItem("products_materials_production_data_async")
    if (startData) {
      setMaterials(JSON.parse(startData))
    }

    const url = `${API.url}get-operational-processing-materials?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("products_materials_production_data_async", JSON.stringify(data.data))
        const finalData = localStorage.getItem("products_materials_production_data_async")
        setMaterials(JSON.parse(finalData))
      })
      .catch(err => {
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    getProductsData()
    getProductionMaterials()
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!products || !materials ? (<MainLoading />) : (
        <>
          <ArgonBox py={3}>
            <Card style={{ padding: 10, boxShadow: `1px 4px 2px 4px lightgrey`, marginBottom: 20 }}>
              <Grid container spacing={3} mb={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <Link to={'/products'}>
                    <DetailedStatisticsCard
                      title="Available Products"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faCalendarDay} /> }}
                      count={``}
                      percentage={{
                        color: "error", count: "", text: (
                          <ArgonBox>
                            <ArgonBox display="flex" flexDirection="column">
                              {products.map((item, key) =>
                                <ArgonBox>
                                  <ArgonTypography key={key} variant="button" fontWeight="medium">{item.name}</ArgonTypography>
                                  <br />
                                  <ArgonTypography color="text" variant="button">HQ: {Intl.NumberFormat().format(item.available_quantity)} PCS</ArgonTypography>
                                  <br />
                                  <ArgonTypography color="text" variant="button">Regional Store: {Intl.NumberFormat().format(item.regional_quantity)} PCS</ArgonTypography>
                                  <br />
                                  <ArgonTypography color="text" variant="button">Sales: {Intl.NumberFormat().format(item.seller_quantity)} PCS</ArgonTypography>
                                  <hr />
                                </ArgonBox>
                              )}
                            </ArgonBox>
                          </ArgonBox>
                        )
                      }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Link to={'/processing-materials'}>
                    <DetailedStatisticsCard
                      title="Production Materials"
                      count={``}
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faCalendarDays} /> }}
                      percentage={{
                        color: "error", count: "", text: (
                          <ArgonBox>
                            <ArgonBox display="flex" flexDirection="column">
                              {materials.map((item, key) =>
                                <ArgonTypography key={key} variant="button" color="text" fontWeight="medium">{item.name}: {Intl.NumberFormat().format(item.quantity)} PCS</ArgonTypography>
                              )}
                            </ArgonBox>
                            <hr />
                          </ArgonBox>
                        )
                      }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Link to={'/requested-processing-materials'}>
                    <DetailedStatisticsCard
                      title="Requested Materials"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faHandHoldingDroplet} /> }}
                      percentage={{ color: "error", count: "", text: "" }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Link to={'/requested-processing-materials-history'}>
                    <DetailedStatisticsCard
                      title="Requested Materials History"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faMoneyBill} /> }}
                      percentage={{ color: "error", count: "", text: "" }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Link to={'/materials-purchases'}>
                    <DetailedStatisticsCard
                      title="Materials Purchases"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faMoneyBill} /> }}
                      percentage={{ color: "error", count: "", text: "" }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Link to={'/materials-usage-history'}>
                    <DetailedStatisticsCard
                      title="Materials Usage History"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faHistory} /> }}
                      percentage={{ color: "error", count: "", text: "" }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Link to={'/sales-products-request-history'}>
                    <DetailedStatisticsCard
                      title="Sales Request Products History"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faHistory} /> }}
                      percentage={{ color: "error", count: "", text: "" }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Link to={'/regional-products-request-history'}>
                    <DetailedStatisticsCard
                      title="Regional Request Products History"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faHistory} /> }}
                      percentage={{ color: "error", count: "", text: "" }}
                    />
                  </Link>
                </Grid>

              </Grid>
            </Card>
          </ArgonBox>
          <Footer />
        </>
      )}
    </DashboardLayout>
  );
}

export default InventoryDashboard;
