import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import VirtualReality from "layouts/virtual-reality";
import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import Regions from "layouts/regions";
import Districts from "layouts/districts";
import Wards from "layouts/wards";
import Users from "layouts/users";
import PayRoll from "layouts/payroll";
import PayrollHistory from "layouts/payrollHistory";
import Products from "layouts/products";
import ProcessingMaterials from "layouts/processingMaterials";
import Farmers from "layouts/farmers";
import AccountantPayroll from "layouts/payrollAccountant";
import AdminPayroll from "layouts/payrollAdmin";
import AllSales from "layouts/allSales";
import DailySales from "layouts/dailySales";
import SalesOnCredit from "layouts/salesOnCredit";
import PaidSalesOnCredit from "layouts/paidSalesOnCredit";
import Shops from "layouts/shops";
import GrossReport from "layouts/grossReport";
import SalesReport from "layouts/salesReport";
import PayRollMainRoot from "layouts/payrollMainRoot";
import RequestedMaterials from "layouts/requestedMaterials";
import MaterialsUsageHistory from "layouts/materialsUsageHistory";
import MaterialsPurchases from "layouts/materialsPurchases";
import MaterialsRequestHistory from "layouts/materialRequestHistory";
import RequestLeave from "layouts/requestLeave";
import RequestLeaveHistory from "layouts/requestLeaveHistory";
import SalesOnCash from "layouts/salesOnCash";
import Expenses from "layouts/expenses";
import SalesProductsRequestHistory from "layouts/salesProductsRequestHistory";
import RegionalProductsRequestHistory from "layouts/regionalProductsRequestHistory";
import RequestedFunds from "layouts/requestedFunds";
import RequestedFundsHistory from "layouts/requestedFundsHistory";
import BusinessTransaction from "layouts/businessTransaction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker, faMapMarkerAlt, faMoneyBillTransfer, faMoneyBillTrendUp, faProjectDiagram, faUniversalAccess, faUsers, faUsersBetweenLines } from "@fortawesome/free-solid-svg-icons";
import ClientsDashboard from "layouts/clientsDashboard";
import ClientsTransactionHistory from "layouts/clientsTransactionHistory";
import ClientsOrders from "layouts/clientsOrders";
import ProcessedClientsOrders from "layouts/processedClientsOrders";
import SalesDashboard from "layouts/salesDashboard";
import { faChartBar, faMoneyBill1 } from "@fortawesome/free-regular-svg-icons";
import MonthlySales from "layouts/monthlySales";
import ProductsPriceLevels from "layouts/productsPriceLevels";
import TransferSalesProducts from "layouts/transferSalesProducts";
import CreditSalesReport from "layouts/creditSalesReport";
import AccountsDashboard from "layouts/accountsDashboard";
import AccountPayable from "layouts/accountPayable";
import PaidDebts from "layouts/paidDebts";
import InventoryDashboard from "layouts/inventoryDashboard";
import ExpensesDashboard from "layouts/expensesDashboard";
import ExpensesTypes from "layouts/expensesTypes";
import LocationDashboard from "layouts/locationDashboard";
import { faDashcube } from "@fortawesome/free-brands-svg-icons";
import HRDashboard from "layouts/hrDashboard";

const role = localStorage.getItem("role")

const routes = [
  {
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    display: "YES",
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-tv-2" />,
    component: <Dashboard />,
  },
  {
    type: "route",
    name: "Business Transaction",
    key: "business-transaction",
    route: "/business-transaction",
    display: "YES",
    icon: <FontAwesomeIcon icon={faMoneyBillTransfer} size="sm" />,
    component: <BusinessTransaction />,
  },
  {
    type: "route",
    name: "Our Clients",
    key: "our-clients",
    route: "/our-clients",
    display: "YES",
    icon: <FontAwesomeIcon icon={faUsers} size="sm" />,
    component: <ClientsDashboard />,
  },
  {
    type: "route",
    name: "Sales",
    key: "sales-dashboard",
    route: "/sales-dashboard",
    display: "YES",
    icon: <FontAwesomeIcon icon={faChartBar} size="sm" />,
    component: <SalesDashboard />,
  },
  {
    type: "route",
    name: "Accounts",
    key: "accounts",
    route: "/accounts",
    display: "YES",
    icon: <FontAwesomeIcon icon={faMoneyBill1} size="sm" />,
    component: <AccountsDashboard />,
  },
  {
    type: "route",
    name: "Inventory",
    key: "inventory",
    route: "/inventory",
    display: "YES",
    icon: <FontAwesomeIcon icon={faProjectDiagram} size="sm" />,
    component: <InventoryDashboard />,
  },
  {
    type: "route",
    name: "Expenses",
    key: "expenses-dashboard",
    route: "/expenses-dashboard",
    display: "YES",
    icon: <FontAwesomeIcon icon={faMoneyBillTrendUp} size="sm" />,
    component: <ExpensesDashboard />,
  },
  {
    type: "route",
    name: "Location",
    key: "location-dashboard",
    route: "/location-dashboard",
    display: "YES",
    icon: <FontAwesomeIcon icon={faMapMarkerAlt} size="sm" />,
    component: <LocationDashboard />,
  },
  {
    type: "route",
    name: "HR Administration",
    key: "hr-dashboard",
    route: "/hr-dashboard",
    display: "YES",
    icon: <FontAwesomeIcon icon={faDashcube} size="sm" />,
    component: <HRDashboard />,
  },
  {
    type: "route",
    name: "Farmers",
    key: "farmers",
    route: "/farmers",
    display: "YES",
    icon: <FontAwesomeIcon icon={faUsersBetweenLines} size="sm" />,
    component: <Farmers />,
  },









  // Routes

  { type: "title", title: "Users & Roles", key: "users-and-roles", display: "NO" },
  {
    type: "route",
    name: "Users",
    key: "users",
    route: "/users",
    display: "NO",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-single-02" />,
    component: <Users />,
  },
  {
    type: "route",
    name: "Account Payable",
    key: "account-payable",
    route: "/account-payable",
    display: "NO",
    icon: <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-tv-2" />,
    component: <AccountPayable />,
  },
  {
    type: "route",
    name: "Paid Debts",
    key: "paid-debts",
    route: "/paid-debts",
    display: "NO",
    icon: <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-tv-2" />,
    component: <PaidDebts />,
  },
  {
    type: "route",
    name: "Clients",
    key: "clients",
    route: "/clients",
    display: "NO",
    icon: <ArgonBox component="i" color="secondary" fontSize="14px" className="ni ni-tv-2" />,
    component: <Shops />,
  },
  {
    type: "route",
    name: `Requested Leave`,
    key: "request-leave",
    route: "/request-leave",
    display: "NO",
    icon: <ArgonBox component="i" color="secondary" fontSize="14px" className="ni ni-tv-2" />,
    component: <RequestLeave />,
  },
  {
    type: "route",
    name: `Requested Leave History`,
    key: "request-leave-history",
    route: "/request-leave-history",
    display: "NO",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-tv-2" />,
    component: <RequestLeaveHistory />,
  },



  { type: "title", title: "Products & Proccessing", key: "products-and-processing", display: "NO" },
  {
    type: "route",
    name: "Products",
    key: "products",
    route: "/products",
    display: "NO",
    icon: <ArgonBox component="i" color="danger" fontSize="14px" className="ni ni-tv-2" />,
    component: <Products />,
  },
  {
    type: "route",
    name: "Products Price Levels",
    key: "products-price-levels",
    route: "/products-price-levels",
    display: "NO",
    icon: <ArgonBox component="i" color="danger" fontSize="14px" className="ni ni-tv-2" />,
    component: <ProductsPriceLevels />,
  },
  {
    type: "route",
    name: "Transfer Products",
    key: "transfer-products",
    route: "/transfer-products",
    display: "NO",
    icon: <ArgonBox component="i" color="danger" fontSize="14px" className="ni ni-tv-2" />,
    component: <TransferSalesProducts />,
  },
  {
    type: "route",
    name: "Processing Materials",
    key: "processing-materials",
    route: "/processing-materials",
    display: "NO",
    icon: <ArgonBox component="i" color="secondary" fontSize="14px" className="ni ni-tv-2" />,
    component: <ProcessingMaterials />,
  },
  {
    type: "route",
    name: `Requested Materials`,
    key: "requested-processing-materials",
    route: "/requested-processing-materials",
    display: "NO",
    icon: <ArgonBox component="i" color="secondary" fontSize="14px" className="ni ni-tv-2" />,
    component: <RequestedMaterials />,
  },
  {
    type: "route",
    name: `Materials Request History`,
    key: "requested-processing-materials-history",
    route: "/requested-processing-materials-history",
    display: "NO",
    icon: <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-tv-2" />,
    component: <MaterialsRequestHistory />,
  },
  {
    type: "route",
    name: "Materials Usage History",
    key: "materials-usage-history",
    route: "/materials-usage-history",
    display: "NO",
    icon: <ArgonBox component="i" color="success" fontSize="14px" className="ni ni-tv-2" />,
    component: <MaterialsUsageHistory />,
  },
  {
    type: "route",
    name: "Materials Purchases",
    key: "materials-purchases",
    route: "/materials-purchases",
    display: "NO",
    icon: <ArgonBox component="i" color="danger" fontSize="14px" className="ni ni-tv-2" />,
    component: <MaterialsPurchases />,
  },
  {
    type: "route",
    name: `Sales Products History`,
    key: "sales-products-request-history",
    route: "/sales-products-request-history",
    display: "NO",
    icon: <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-tv-2" />,
    component: <SalesProductsRequestHistory />,
  },
  {
    type: "route",
    name: `Regional Products History`,
    key: "regional-products-request-history",
    route: "/regional-products-request-history",
    display: "NO",
    icon: <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-tv-2" />,
    component: <RegionalProductsRequestHistory />,
  },


  { type: "title", title: "Sales Reports", key: "sales-reports", display: "NO" },
  {
    type: "route",
    name: "Today Sales",
    key: "today-sales",
    route: "/today-sales",
    display: "NO",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-tv-2" />,
    component: <DailySales />,
  },
  {
    type: "route",
    name: "Monthly Sales",
    key: "monthly-sales",
    route: "/monthly-sales",
    display: "NO",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-tv-2" />,
    component: <MonthlySales />,
  },
  {
    type: "route",
    name: "All Sales",
    key: "all-sales",
    route: "/all-sales",
    display: "NO",
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-tv-2" />,
    component: <AllSales />,
  },
  {
    type: "route",
    name: "Gross Report",
    key: "gross-report",
    route: "/gross-report",
    display: "NO",
    icon: <ArgonBox component="i" color="secondary" fontSize="14px" className="ni ni-tv-2" />,
    component: <GrossReport />,
  },
  {
    type: "route",
    name: "Clients Transactions",
    key: "clients-transaction",
    route: "/clients-transaction",
    display: "NO",
    icon: <ArgonBox component="i" color="secondary" fontSize="14px" className="ni ni-tv-2" />,
    component: <ClientsTransactionHistory />,
  },
  {
    type: "route",
    name: "Sales on Cash",
    key: "sales-on-cash",
    route: "/sales-on-cash",
    display: "NO",
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-tv-2" />,
    component: <SalesOnCash />,
  },
  {
    type: "route",
    name: "Credit Sales Report",
    key: "sales-credit-report",
    route: "/sales-credit-report",
    display: "NO",
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-tv-2" />,
    component: <CreditSalesReport />,
  },
  {
    type: "route",
    name: "Credit Sales Report",
    key: "sales-on-credit",
    route: "/sales-on-credit",
    display: "NO",
    icon: <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-tv-2" />,
    component: <SalesOnCredit />,
  },
  {
    type: "route",
    name: "Madeni yaliyolipwa",
    key: "sales-on-credit-collected",
    route: "/sales-on-credit-collected",
    display: "NO",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-tv-2" />,
    component: <PaidSalesOnCredit />,
  },
  {
    type: "route",
    name: "Sales Overview",
    key: "sales-overview",
    route: "/sales-overview",
    display: "NO",
    icon: <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-tv-2" />,
    component: <SalesReport />,
  },
  {
    type: "route",
    name: "Clients Orders",
    key: "clients-orders",
    route: "/clients-orders",
    display: "NO",
    icon: <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-tv-2" />,
    component: <ClientsOrders />,
  },

  {
    type: "route",
    name: "Processed Clients Orders",
    key: "processed-clients-orders",
    route: "/processed-clients-orders",
    display: "NO",
    icon: <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-tv-2" />,
    component: <ProcessedClientsOrders />,
  },



  { type: "title", title: "Accounting", key: "request-fund-support", display: "NO" },
  {
    type: "route",
    name: `Requested Funds`,
    key: "requested-funds",
    route: "/requested-funds",
    display: "NO",
    icon: <ArgonBox component="i" color="secondary" fontSize="14px" className="ni ni-tv-2" />,
    component: <RequestedFunds />,
  },
  {
    type: "route",
    name: `Requested Funds History`,
    key: "requested-funds-history",
    route: "/requested-funds-history",
    display: "NO",
    icon: <ArgonBox component="i" color="success" fontSize="14px" className="ni ni-tv-2" />,
    component: <RequestedFundsHistory />,
  },
  {
    type: "route",
    name: "Expenses Types",
    key: "expenses-types",
    route: "/expenses-types",
    display: "NO",
    icon: (
      <ArgonBox component="i" color="secondary" fontSize="14px" className="ni ni-tv-2" />
    ),
    component: <ExpensesTypes />,
  },
  {
    type: "route",
    name: "Expenses",
    key: "expenses",
    route: "/expenses",
    display: "NO",
    icon: (
      <ArgonBox component="i" color="secondary" fontSize="14px" className="ni ni-tv-2" />
    ),
    component: <Expenses />,
  },
  {
    type: "route",
    name: "Payoll",
    key: "payroll",
    route: "/payroll",
    display: "NO",
    icon: <ArgonBox component="i" color="secondary" fontSize="14px" className="ni ni-tv-2" />,
    component: <PayRollMainRoot />,
  },
  {
    type: "route",
    name: "Payoll History",
    key: "payroll-history",
    route: "/payroll-history",
    display: "NO",
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-tv-2" />,
    component: <PayrollHistory />,
  },

  { type: "title", title: "Location", key: "location", display: "NO" },

  {
    type: "route",
    name: "Regions",
    key: "regions",
    route: "/regions",
    display: "NO",
    icon: (
      <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-tv-2" />
    ),
    component: <Regions />,
  },
  {
    type: "route",
    name: "Districts",
    key: "districts",
    route: "/districts",
    display: "NO",
    icon: (
      <ArgonBox component="i" color="success" fontSize="14px" className="ni ni-tv-2" />
    ),
    component: <Districts />,
  },
  {
    type: "route",
    name: "Wards",
    key: "wards",
    route: "/wards",
    display: "NO",
    icon: (
      <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-tv-2" />
    ),
    component: <Wards />,
  },
  // {
  //   type: "route",
  //   name: "Billing",
  //   key: "billing",
  //   route: "/billing",
  //   icon: <ArgonBox component="i" color="success" fontSize="14px" className="ni ni-credit-card" />,
  //   component: <Billing />,
  // },
  // {
  //   type: "route",
  //   name: "Virtual Reality",
  //   key: "virtual-reality",
  //   route: "/virtual-reality",
  //   icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-app" />,
  //   component: <VirtualReality />,
  // },
  // {
  //   type: "route",
  //   name: "RTL",
  //   key: "rtl",
  //   route: "/rtl",
  //   icon: <ArgonBox component="i" color="error" fontSize="14px" className="ni ni-world-2" />,
  //   component: <RTL />,
  // },
  { type: "title", title: "Account", key: "account-pages", display: "NO" },
  {
    type: "route",
    name: "Profile",
    key: "profile",
    route: "/profile",
    display: "YES",
    icon: <ArgonBox component="i" color="dark" fontSize="14px" className="ni ni-single-02" />,
    component: <Profile />,
  },
  {
    type: "route",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    display: "NO",
    icon: (
      <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-single-copy-04" />
    ),
    component: <SignIn />,
  },
  {
    type: "route",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    display: "NO",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-collection" />,
    component: <SignUp />,
  },
];

export default routes;
