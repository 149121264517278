import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import { modalSettings } from "constants/ModalSettins";
import { FormGroup } from "@mui/material";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import { SelectStyle } from "constants/SelectStyle";
import { useNavigate } from "react-router-dom";

function Wards() {
  const [registerModal, setRegisterModal] = useState(false)
  const [name, setName] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [apiWards, setApiWards] = useState([])
  const [apiWardsSearch, setApiWardsSearch] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [selectedUpdate, setSelectUpdate] = useState("")
  const [updateModal, setUpdateModal] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [apiRegions, setApiRegions] = useState([])
  const [region, setRegion] = useState("")
  const [district, setDistrict] = useState("")
  const [districtsData, setDistrictsData] = useState([])

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const getRegionsData = () => {
    const startData = localStorage.getItem("regions_data_async")
    if (startData) {
      setApiRegions(JSON.parse(startData))
      setApiLoading(false)
    }

    const url = `${API.url}get-all-regions?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("regions_data_async", JSON.stringify(data.data))
        const finalData = localStorage.getItem("regions_data_async")
        setApiRegions(JSON.parse(finalData))
      })
      .catch(err => {
        swal("Opps!", err.message, "warning");
      })
  }

  const getRegionDistricts = (region_id) => {
    setRegion(region_id)
    const url = `${API.url}get-districts-by-region/${encode(region_id)}?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setDistrictsData(data.data)
      })
      .catch(err => {
        swal("Opps!", err.message, "warning");
      })
  }

  const completeRowsData = apiWardsSearch.length > 0 ? apiWardsSearch : apiWards

  const UsersData = {
    columns: [
      { name: "sn", align: "left" },
      { name: "ward_name", align: "left" },
      { name: "district_name", align: "left" },
      { name: "registered_by", align: "left" },
      { name: "created_at", align: "center" },
      { name: "action", align: "center" },
    ],

    rows: completeRowsData.map((item, idx) => {
      const data = {
        sn: <p style={{ padding: 10 }}>{++idx}</p>,
        ward_name: item.name,
        district_name: item.district_name,
        registered_by: item.registered_by,
        created_at: item.created_at,

        action: (
          <>
            <ArgonButton
              color="info"
              size="small"
              onClick={() => {
                setSelectUpdate(item.id)
                setName(item.name)
                setUpdateModal(true)
              }}
            >
              Edit
            </ArgonButton>
            <ArgonButton style={{ marginLeft: 5 }} size="small" color="warning" onClick={() => deleteRegionFunction(item.id)} >delete</ArgonButton>
          </>
        ),
      }
      return data
    }),
  };

  const { columns, rows } = UsersData;


  const getApiFirstData = () => {
    const url = `${API.url}get-all-wards?page=1&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Wards_data_async", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Wards_data_async")
        setApiWards(JSON.parse(finalData))
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const getApiData = () => {
    const startData = localStorage.getItem("Wards_data_async")
    if (startData) {
      setApiWards(JSON.parse(startData))
      setApiLoading(false)
      setEndLoading(false)
    }

    const url = `${API.url}get-all-wards?page=${page}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Wards_data_async", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Wards_data_async")
        setApiWards(JSON.parse(finalData))

        setApiLoading(false)
        setEndLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getRegionsData()
    getApiData()
  }, [page]);

  const deleteRegionFunction = (region_id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteWardFunctionComplete(region_id)
        } else {
          swal("Your data is safe!");
        }
      });
  }

  const deleteWardFunctionComplete = (region_id) => {
    setApiLoading(true)
    const url = `${API.url}delete-ward/${encode(region_id)}?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(res => {
        setApiLoading(false)
        getApiFirstData()
        swal(res.result, res.message, "success");
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const registerWardFunction = () => {
    if (name === "" || region === "") {
      swal("Opps!", "Please fill all required informations", "warning");
    } else {
      setIsLoading(true)
      setTimeout(() => {
        const formData = new FormData()
        formData.append("registered_by", encode(localStorage.getItem("user_id")))
        formData.append("district_id", encode(district))
        formData.append("name", encode(name))
        fetch(`${API.url}register-ward?key=${API.key}`, { method: "POST", body: formData })
          .then(res => res.json())
          .then(res => {
            getApiFirstData()
            setRegisterModal(false)
            setIsLoading(false)
            if (res.result === "SUCCESS") {
              setName("")
              setRegion("")
              setDistrict("")
              swal(res.result, res.message, "success")
            } else {
              swal(res.result, res.message, "warning")
            }
          })
          .catch(err => {
            setIsLoading(false)
            swal("Opps!", err.message, "warning");
          })
      }, 1000);
    }
  }

  const updateWardFunction = () => {
    if (name === "") {
      swal("Opps!", "Please fill all required informations", "warning");
    } else {
      setIsLoading(true)
      setTimeout(() => {
        const formData = new FormData()
        formData.append("ward_id", encode(selectedUpdate))
        formData.append("name", encode(name))
        fetch(`${API.url}update-ward?key=${API.key}`, { method: "PUT", body: formData })
          .then(res => res.json())
          .then(res => {
            setPage(0)
            getApiData()
            setUpdateModal(false)
            setSelectUpdate("")
            setIsLoading(false)
            if (res.result === "SUCCESS") {
              setName("")
              swal(res.result, res.message, "success")
            } else {
              swal(res.result, res.message, "warning")
            }
          })
          .catch(err => {
            setIsLoading(false)
            swal("Opps!", err.message, "warning");
          })
      }, 1000);
    }
  }

  const searchRegionFunction = (search) => {
    const url = `${API.url}get-all-wards-by-search?search=${encode(search)}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiWardsSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  // const handleScroll = (event) => {
  //   const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
  //   const scrollEndY = layoutMeasurement.height + contentOffset.y;
  //   const contentHeight = contentSize.height;

  //   if (scrollEndY >= contentHeight - 50) {
  //     getApiData();
  //   }
  // };

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">Wards Table | Page {page}</ArgonTypography>
                  <ArgonButton onClick={() => setRegisterModal(true)} color="info" size="small">
                    Add Ward
                  </ArgonButton>
                </ArgonBox>

                <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search Here..."
                    onChange={(e) => {
                      searchRegionFunction(e.target.value)
                    }}
                  />
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </ArgonBox>
                <ArgonBox display="flex" p={3}>
                  <ArgonButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        if (page <= 1) {
                          setPage(1)
                          setEndLoading(false)
                        } else {
                          setPage(page - 1)
                        }
                      }, 1000);
                    }}
                  >
                    &laquo; prev
                  </ArgonButton>

                  <ArgonButton
                    style={{ marginLeft: 10 }}
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        setPage(page + 1)
                      }, 1000);
                    }}
                  >
                    next &raquo;
                  </ArgonButton>
                </ArgonBox>
                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
              </Card>
            </ArgonBox>
          </ArgonBox>


          <Modal
            isOpen={registerModal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={modalSettings}
            contentLabel="Register Ward"
          >
            <div style={{ float: "right" }}>
              <ArgonButton color="warning" onClick={() => setRegisterModal(false)}>close</ArgonButton>
            </div>
            <br />
            <h2>Register Ward</h2>
            <FormGroup>
              <select value={region} onChange={(e) => getRegionDistricts(e.target.value)} style={SelectStyle} autoFocus>
                <option>--Select Region--</option>
                {apiRegions.map((item, idx) =>
                  <option key={idx} value={item.id}>{item.name}</option>
                )}
              </select>
            </FormGroup>
            <br />
            <FormGroup>
              <select value={district} onChange={(e) => setDistrict(e.target.value)} style={SelectStyle} autoFocus>
                <option>--Select District--</option>
                {districtsData.map((item, idx) =>
                  <option key={idx} value={item.id}>{item.name}</option>
                )}
              </select>
            </FormGroup>
            <br />
            <FormGroup>
              <ArgonInput onChange={(e) => setName(e.target.value)} value={name} placeholder="Enter Ward Name" />
            </FormGroup>
            <br />
            {isLoading === true ? (
              <Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />
            ) : (
              <ArgonButton onClick={() => registerWardFunction()} color="primary">Register</ArgonButton>
            )}
          </Modal>

          {/* UPDATE MODAL */}
          <Modal
            isOpen={updateModal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={modalSettings}
            contentLabel="Register Ward"
          >
            <div style={{ float: "right" }}>
              <ArgonButton color="warning" onClick={() => setUpdateModal(false)}>close</ArgonButton>
            </div>
            <br />
            <br />
            <h2>Update Ward</h2>
            <FormGroup>
              <ArgonInput autoFocus onChange={(e) => setName(e.target.value)} value={name} placeholder="Enter Ward Name" />
            </FormGroup>
            <br />
            {isLoading === true ? (
              <Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />
            ) : (
              <ArgonButton onClick={() => updateWardFunction()} color="primary">Update</ArgonButton>
            )}
          </Modal>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default Wards;
