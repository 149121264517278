import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
// Argon Dashboard 2 MUI base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components

// Data
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay, faChartPie, faCreditCard, faExchange, faHandHoldingDollar, faHandHoldingDroplet, faHistory, faLineChart, faList, faMapMarkerAlt, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import API from "Api/Api";
import ArgonTypography from "components/ArgonTypography";
import { todayDate } from "constants/StaticDatas";
import { currentMonth } from "constants/StaticDatas";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import MainLoading from "Helpers/MainLoading";

function SalesDashboard() {
  const [dashboardData, setDashboardData] = useState(null)
  const [graphData, setGraphData] = useState(null)

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const getApiData = () => {
    const getData = localStorage.getItem("Dashboard_data_async_New")
    const completeData = JSON.parse(getData)
    if (completeData) {
      setDashboardData(completeData)
    }
    const url = `${API.url}get-dashboard-data?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(res => {
        localStorage.setItem("Dashboard_data_async_New", JSON.stringify(res.data))
        const getData = localStorage.getItem("Dashboard_data_async_New")
        const completeData = JSON.parse(getData)
        setDashboardData(completeData)
      })
      .catch(err => { })
  }

  useEffect(() => {
    getApiData()
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!dashboardData ? (<MainLoading />) : (
        <>
          <ArgonBox py={3}>
            <Card style={{ padding: 10, boxShadow: `1px 4px 2px 4px lightgrey`, marginBottom: 20 }}>
              <Grid container spacing={3} mb={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <Link to={'/today-sales'}>
                    <DetailedStatisticsCard
                      title="today's sales"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faCalendarDay} /> }}
                      count={`TZS ${Intl.NumberFormat().format(dashboardData.total_daily_sales)}`}
                      percentage={{
                        color: "error", count: "", text: (
                          <ArgonBox>
                            <ArgonBox display="flex" flexDirection="column">
                              {dashboardData.daily_summary.map((item, key) =>
                                <ArgonTypography key={key} variant="button" color="text" fontWeight="medium">{item.name}: {Intl.NumberFormat().format(item.quantity)} PCS</ArgonTypography>
                              )}
                            </ArgonBox>
                            <hr />
                            <ArgonTypography variant="button" color="text" fontWeight="medium">Date: {todayDate}</ArgonTypography>
                          </ArgonBox>
                        )
                      }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Link to={'/monthly-sales'}>
                    <DetailedStatisticsCard
                      title="Monthly sales"
                      count={`TZS ${Intl.NumberFormat().format(dashboardData.total_monthly_sales)}`}
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faCalendarDays} /> }}
                      percentage={{
                        color: "error", count: "", text: (
                          <ArgonBox>
                            <ArgonBox display="flex" flexDirection="column">
                              {dashboardData.monthly_summary.map((item, key) =>
                                <ArgonTypography key={key} variant="button" color="text" fontWeight="medium">{item.name}: {Intl.NumberFormat().format(item.quantity)} PCS</ArgonTypography>
                              )}
                            </ArgonBox>
                            <hr />
                            <ArgonTypography variant="button" color="text" fontWeight="medium">{currentMonth}</ArgonTypography>
                          </ArgonBox>
                        )
                      }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/products'}>
                    <DetailedStatisticsCard
                      title="Products"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faList} /> }}
                      percentage={{ color: "error", count: "", text: "" }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/products-price-levels'}>
                    <DetailedStatisticsCard
                      title="Products Price Levels"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faMapMarkerAlt} /> }}
                      percentage={{ color: "error", count: "", text: "" }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/transfer-products'}>
                    <DetailedStatisticsCard
                      title="Transfer Sales Products"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faExchange} /> }}
                      percentage={{ color: "error", count: "", text: "" }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/gross-report'}>
                    <DetailedStatisticsCard
                      title="Gross Sales Report"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faChartPie} /> }}
                      percentage={{ color: "error", count: "", text: "" }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/sales-on-cash'}>
                    <DetailedStatisticsCard
                      title="Cash Sales Report"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faHandHoldingDollar} /> }}
                      percentage={{ color: "error", count: "", text: "" }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/sales-on-credit'}>
                    <DetailedStatisticsCard
                      title="Accounts Receivable"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faHandHoldingDollar} /> }}
                      percentage={{ color: "error", count: "", text: "" }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/sales-credit-report'}>
                    <DetailedStatisticsCard
                      title="Credit Sales Report"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faCreditCard} /> }}
                      percentage={{ color: "error", count: "", text: "" }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/all-sales'}>
                    <DetailedStatisticsCard
                      title="All Sales Report"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faList} /> }}
                      percentage={{ color: "error", count: "", text: "" }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/sales-overview'}>
                    <DetailedStatisticsCard
                      title="Sales Overview"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faLineChart} /> }}
                      percentage={{ color: "error", count: "", text: "" }}
                    />
                  </Link>
                </Grid>

              </Grid>
            </Card>
          </ArgonBox>
          <Footer />
        </>
      )}
    </DashboardLayout>
  );
}

export default SalesDashboard;
