export const modalSettings = {
  content: {
    top: '50%',
    left: '59%',
    zIndex: 200,
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: "75%",
    overlow: "auto",
    boxShadow: `0px 2px 10px 10px grey`,
    borderRadius: "20px",
    maxHeight: "90%",
    // textAlign:"center"
  },
};