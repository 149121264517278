import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import { modalSettings } from "constants/ModalSettins";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import { SelectStyle } from "constants/SelectStyle";
import ArgonAvatar from "components/ArgonAvatar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

function Farmers() {
  const [registerModal, setRegisterModal] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [middleName, setMiddleName] = useState("")
  const [nida, setNida] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [role, setRole] = useState("")
  const [address, setAddress] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [apiWards, setApiWards] = useState([])
  const [apiWardsSearch, setApiWardsSearch] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [selectedUpdate, setSelectUpdate] = useState("")
  const [profileModal, setProfileModal] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [apiRegions, setApiRegions] = useState([])
  const [region, setRegion] = useState("")
  const [district, setDistrict] = useState("")
  const [ward, setWard] = useState("")
  const [districtsData, setDistrictsData] = useState([])
  const [wardsData, setWardsData] = useState([])
  const [userPhoto, setUserPhoto] = useState("")
  const [accountStatus, setAccountStatus] = useState("")
  const [userSalary, setUserSalary] = useState("")

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const getRegionsData = () => {
    const startData = localStorage.getItem("regions_data_async")
    if (startData) {
      setApiRegions(JSON.parse(startData))
      setApiLoading(false)
    }

    const url = `${API.url}get-all-regions?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("regions_data_async", JSON.stringify(data.data))
        const finalData = localStorage.getItem("regions_data_async")
        setApiRegions(JSON.parse(finalData))
      })
      .catch(err => {
        swal("Opps!", err.message, "warning");
      })
  }

  const getRegionDistricts = (region_id) => {
    setRegion(region_id)
    const url = `${API.url}get-districts-by-region/${encode(region_id)}?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setDistrictsData(data.data)
      })
      .catch(err => {
        swal("Opps!", err.message, "warning");
      })
  }

  const getDistrictWards = (district_id) => {
    setDistrict(district_id)
    const url = `${API.url}get-wards-by-district/${encode(district_id)}?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setWardsData(data.data)
      })
      .catch(err => {
        swal("Opps!", err.message, "warning");
      })
  }

  const completeRowsData = apiWardsSearch.length > 0 ? apiWardsSearch : apiWards

  const WardsData = {
    columns: [
      { name: "name", align: "left" },
      { name: "status", align: "left" },
      { name: "region", align: "left" },
      { name: "district", align: "left" },
      { name: "registered_by", align: "left" },
      { name: "created_at", align: "center" },
      { name: "action", align: "center" },
    ],

    rows: completeRowsData.map((item, idx) => {
      const data = {
        name: <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
          <ArgonBox mr={2}>
            <ArgonAvatar src={item.photo} alt={`${item.first_name} ${item.middle_name} ${item.last_name}`} size="sm" variant="rounded" />
          </ArgonBox>
          <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography variant="button" fontWeight="medium">
              {`${item.first_name} ${item.middle_name} ${item.last_name}`}
            </ArgonTypography>
            <ArgonTypography variant="caption" color="secondary">
              {item.phone_number}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>,
        status: <ArgonBox display="flex" flexDirection="column">
          <ArgonButton style={{ fontSize: 10, padding: 5 }} color={item.status === "AVAILABLE" ? "secondary" : "warning"} size="small">
            {item.status}
          </ArgonButton>
        </ArgonBox>,
        region: item.region,
        district: item.district,
        registered_by: item.registered_by,
        created_at: item.created_at,

        action: (
          <>
            <ArgonButton
              color="secondary"
              size="small"
              onClick={() => {
                setSelectUpdate(item.id)
                setFirstName(item.first_name)
                setMiddleName(item.middle_name)
                setLastName(item.last_name)
                setRegion(item.region_id)
                setDistrict(item.district_id)
                setWard(item.ward_id)
                setRole(item.role)
                setAddress(item.address)
                setNida(item.nida)
                setPhoneNumber(item.phone_number)
                setRegisterModal(true)
              }}
            >
              Edit
            </ArgonButton>

            <ArgonButton
              style={{ marginLeft: 5 }}
              size="small" color="info"
              onClick={() => {
                setSelectUpdate(item.id)
                setFirstName(item.first_name)
                setMiddleName(item.middle_name)
                setLastName(item.last_name)
                setRegion(item.region)
                setDistrict(item.district)
                setWard(item.ward)
                setRole(item.role)
                setAddress(item.address)
                setNida(item.nida)
                setPhoneNumber(item.phone_number)
                setUserPhoto(item.photo)
                setAccountStatus(item.status)
                setProfileModal(true)
              }} >
              View Profile
            </ArgonButton>
            <br />
            <ArgonButton
              style={{ marginLeft: 6 }}
              color="primary"
              size="small"
              onClick={() => {
                window.open(`https://maps.google.com/?q=${item.latitude},${item.longitude}`, `_blank`)
              }}
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              View in Map
            </ArgonButton>
            <ArgonButton style={{ marginLeft: 5 }} size="small" color={item.status === "AVAILABLE" ? "warning" : "success"} onClick={() => deleteUserFunction(item.id)} >{item.status === "AVAILABLE" ? "Suspend" : "Activate"}</ArgonButton>
          </>
        ),
      }
      return data
    }),
  };

  const { columns, rows } = WardsData;

  const getApiFirstData = () => {
    const url = `${API.url}get-all-farmers?page=1&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Farmers_data_async", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Farmers_data_async")
        setApiWards(JSON.parse(finalData))
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const getApiData = () => {
    const startData = localStorage.getItem("Farmers_data_async")
    if (startData) {
      setApiWards(JSON.parse(startData))
      setApiLoading(false)
      setEndLoading(false)
    }

    const url = `${API.url}get-all-farmers?page=${page}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Farmers_data_async", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Farmers_data_async")
        setApiWards(JSON.parse(finalData))

        setApiLoading(false)
        setEndLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getRegionsData()
    getApiData()
  }, [page]);

  const deleteUserFunction = (region_id) => {
    swal({
      title: "Hold on!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteUserFunctionComplete(region_id)
        } else {
          swal("Action cancelled!");
        }
      });
  }

  const deleteUserFunctionComplete = (region_id) => {
    setApiLoading(true)
    const url = `${API.url}delete-farmer/${encode(region_id)}?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(res => {
        setApiLoading(false)
        getApiFirstData()
        swal(res.result, res.message, "success");
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const registerUserFunction = () => {
    if (firstName === "" || region === "" || district === "" || ward === "" || firstName === "" || middleName === "" || lastName === "" || phoneNumber === "" || address === "") {
      swal("Opps!", "Please fill all required informations", "warning");
    } else {
      setIsLoading(true)
      setTimeout(() => {
        const formData = new FormData()
        formData.append("registered_by", encode(localStorage.getItem("user_id")))
        formData.append("region", encode(region))
        formData.append("district", encode(district))
        formData.append("ward", encode(ward))
        formData.append("first_name", encode(firstName))
        formData.append("middle_name", encode(middleName))
        formData.append("last_name", encode(lastName))
        formData.append("nida", encode(nida))
        formData.append("salary", encode(userSalary))
        formData.append("phone_number", encode(phoneNumber))
        formData.append("role", encode("FARMER"))
        formData.append("address", encode(address))
        fetch(`${API.url}register-farmer?key=${API.key}`, { method: "POST", body: formData })
          .then(res => res.json())
          .then(res => {
            getApiFirstData()
            setRegisterModal(false)
            setIsLoading(false)
            if (res.result === "SUCCESS") {
              clearHooks()
              swal(res.result, res.message, "success")
            } else {
              swal(res.result, res.message, "warning")
            }
          })
          .catch(err => {
            setIsLoading(false)
            swal("Opps!", err.message, "warning");
          })
      }, 1000);
    }
  }

  const uploadUserImage = (image) => {
    setApiLoading(true)
    const formData = new FormData()
    formData.append("photo", image)
    formData.append("user_id", encode(selectedUpdate))
    fetch(`${API.url}update-farmer-photo?key=${API.key}`, { method: "PUT", body: formData })
      .then(res => res.json())
      .then(res => {
        getApiFirstData()
        setProfileModal(false)
        setApiLoading(false)
        if (res.result === "SUCCESS") {
          swal(res.result, res.message, "success")
        } else {
          swal(res.result, res.message, "warning")
        }
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const updateUserFunction = () => {
    if (firstName === "" || region === "" || district === "" || ward === "" || firstName === "" || middleName === "" || lastName === "" || phoneNumber === "" || address === "") {
      swal("Opps!", "Please fill all required informations", "warning");
    } else {
      setIsLoading(true)
      setTimeout(() => {
        const formData = new FormData()
        formData.append("user_id", encode(selectedUpdate))
        formData.append("registered_by", encode(localStorage.getItem("user_id")))
        formData.append("region", encode(region))
        formData.append("district", encode(district))
        formData.append("ward", encode(ward))
        formData.append("first_name", encode(firstName))
        formData.append("middle_name", encode(middleName))
        formData.append("last_name", encode(lastName))
        formData.append("nida", encode(nida))
        formData.append("phone_number", encode(phoneNumber))
        formData.append("role", encode("FARMER"))
        formData.append("address", encode(address))
        fetch(`${API.url}update-farmer?key=${API.key}`, { method: "PUT", body: formData })
          .then(res => res.json())
          .then(res => {
            setPage(0)
            getApiData()
            clearHooks()
            setRegisterModal(false)
            setIsLoading(false)
            if (res.result === "SUCCESS") {
              swal(res.result, res.message, "success")
            } else {
              swal(res.result, res.message, "warning")
            }
          })
          .catch(err => {
            setIsLoading(false)
            swal("Opps!", err.message, "warning");
          })
      }, 1000);
    }
  }

  const searchUsersFunction = (search) => {
    const url = `${API.url}get-all-farmers-by-search?search=${encode(search)}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiWardsSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const clearHooks = () => {
    setSelectUpdate("")
    setFirstName("")
    setMiddleName("")
    setLastName("")
    setNida("")
    setPhoneNumber("")
    setAddress("")
    setRegion("")
    setDistrict("")
    setWard("")
    setUserPhoto("")
    setAccountStatus("")
    setUserSalary("")
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">Farmers Table | Page {page}</ArgonTypography>
                  {/* <ArgonButton onClick={() => setRegisterModal(true)} color="info" size="small">
                    Add Farmer
                  </ArgonButton> */}
                </ArgonBox>

                <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search Here..."
                    onChange={(e) => {
                      searchUsersFunction(e.target.value)
                    }}
                  />
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </ArgonBox>
                <ArgonBox display="flex" p={3}>
                  <ArgonButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        if (page <= 1) {
                          setPage(1)
                          setEndLoading(false)
                        } else {
                          setPage(page - 1)
                        }
                      }, 1000);
                    }}
                  >
                    &laquo; prev
                  </ArgonButton>

                  <ArgonButton
                    style={{ marginLeft: 10 }}
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        setPage(page + 1)
                      }, 1000);
                    }}
                  >
                    next &raquo;
                  </ArgonButton>
                </ArgonBox>
                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
              </Card>
            </ArgonBox>
          </ArgonBox>


          <Modal
            isOpen={registerModal}
            style={modalSettings}
            // contentLabel="Register Farmer"
            shouldCloseOnEsc={true}
            preventScroll={false}
            role="dialog"
          >
            <div style={{ float: "right" }}>
              <ArgonButton
                color="warning"
                onClick={() => {
                  setSelectUpdate("")
                  setRegisterModal(false)
                }}
              >close</ArgonButton>
            </div>
            <br />
            <h2>{selectedUpdate === "" ? "Register Farmer" : "Update Farmer"}</h2>
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Region</ArgonTypography>
                <select value={region} onChange={(e) => getRegionDistricts(e.target.value)} autoFocus onFocus={(e) => getRegionDistricts(e.target.value)} onClick={(e) => getRegionDistricts(e.target.value)} style={SelectStyle}>
                  <option>--Select Region--</option>
                  {apiRegions.map((item, idx) =>
                    <option key={idx} value={item.id}>{item.name}</option>
                  )}
                </select>
              </div>
              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">District</ArgonTypography>
                <select value={district} onChange={(e) => getDistrictWards(e.target.value)} onClick={(e) => getDistrictWards(e.target.value)} onBlur={(e) => getDistrictWards(e.target.value)} style={SelectStyle}>
                  <option>--Select District--</option>
                  {districtsData.map((item, idx) =>
                    <option key={idx} value={item.id}>{item.name}</option>
                  )}
                </select>
              </div>
              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Ward</ArgonTypography>
                <select value={ward} onClick={(e) => setWard(e.target.value)} onChange={(e) => setWard(e.target.value)} style={SelectStyle}>
                  <option>--Select Ward--</option>
                  {wardsData.map((item, idx) =>
                    <option key={idx} value={item.id}>{item.name}</option>
                  )}
                </select>
              </div>
            </ArgonBox>
            <br />
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Physical Address</ArgonTypography>
                <ArgonInput onChange={(e) => setAddress(e.target.value)} value={address} placeholder="Enter Physical Address" />
              </div>
              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">NIDA</ArgonTypography>
                <ArgonInput onChange={(e) => setNida(e.target.value)} value={nida} placeholder="Enter Nida" />
              </div>
              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Phone Number</ArgonTypography>
                <ArgonInput
                  onChange={(e) => {
                    setPhoneNumber(e.target.value)
                  }}
                  value={phoneNumber}
                  placeholder="Enter Phone Number"
                />
              </div>
            </ArgonBox>
            <br />
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">First Name</ArgonTypography>
                <ArgonInput onChange={(e) => setFirstName(e.target.value)} value={firstName} placeholder="Enter First Name" />
              </div>
              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Middle Name</ArgonTypography>
                <ArgonInput onChange={(e) => setMiddleName(e.target.value)} value={middleName} placeholder="Enter Middle Name" />
              </div>
              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Last Name</ArgonTypography>
                <ArgonInput onChange={(e) => setLastName(e.target.value)} value={lastName} placeholder="Enter Last Name" />
              </div>
            </ArgonBox>
            <br />
            {isLoading === true ? (
              <Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />
            ) : (
              <>{selectedUpdate !== "" ? (
                <ArgonButton onClick={() => updateUserFunction()} color="primary">Update</ArgonButton>
              ) : (
                <ArgonButton onClick={() => registerUserFunction()} color="primary">Register</ArgonButton>
              )}</>
            )}
          </Modal>


          <Modal
            isOpen={profileModal}
            style={modalSettings}
            shouldCloseOnEsc={true}
            preventScroll={false}
            role="dialog"
          >
            <div style={{ float: "right" }}>
              <ArgonButton
                color="warning"
                onClick={() => {
                  clearHooks()
                  setProfileModal(false)
                }}
              >close</ArgonButton>
            </div>

            <ArgonBox>
              <ArgonBox display="flex">
                <div style={{ width: "25%", textAlign: "center", alignItems: "center", alignContent: "center", justifyContent: "center" }} >
                  <ArgonAvatar src={userPhoto} alt={"Image"} size="xxxl" variant="rounded" style={{ borderRadius: 100 }} />
                  <div style={{ marginLeft: 10, marginRight: 20 }}>
                    <ArgonInput onChange={(e) => uploadUserImage(e.target.files[0])} type="file" />
                  </div>
                  <ArgonTypography variant="h5" fontWeight="medium">{role}</ArgonTypography>
                  <ArgonTypography variant="h5" fontWeight="medium">ID: {selectedUpdate}</ArgonTypography>
                </div>
                <div style={{ width: "75%" }}>
                  <ArgonTypography variant="h5" fontWeight="medium">Personal Information</ArgonTypography>
                  <hr />
                  <ArgonBox display="flex" justifyContent="space-between">
                    <div style={{ width: "50%" }}>
                      <ArgonTypography variant="button" color="text" fontWeight="medium">First Name: {firstName}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Middle Name: {middleName}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Last Name: {lastName}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">NIDA: {nida}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Phone Number: {phoneNumber}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Region: {region}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">District: {district}</ArgonTypography>
                      <br />
                    </div>
                    <div style={{ width: "50%" }}>
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Ward: {ward}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Address: {address}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Role: {role}</ArgonTypography>
                      <br />

                      <ArgonButton color={accountStatus === "AVAILABLE" ? "success" : "warning"}>{accountStatus}</ArgonButton>
                    </div>
                  </ArgonBox>
                </div>
              </ArgonBox>
            </ArgonBox>
          </Modal>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default Farmers;
