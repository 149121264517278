import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import { useNavigate } from "react-router-dom";

function MaterialsPurchases() {
  const [apiDatas, setApiDatas] = useState({ rows: [], total_amount: 0, sum_total: 0, total_quantity: 0 })
  const [apiDataSearch, setApiDatasSearch] = useState({ rows: [], total_amount: 0, sum_total: 0, total_quantity: 0 })
  const [apiLoading, setApiLoading] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const completeRowsData = apiDataSearch.rows.length > 0 ? apiDataSearch : apiDatas

  // "id":r.id,
  // "transit_status":transit_status,
  // "order_status":order_status,
  // "driver_details":getDriverDetails(r.id),
  // "material_name":r.material_name,
  // "buying_price":r.buying_price,
  // "quantity":r.quantity,
  // "unit":r.unit,
  // "farmer":getFarmerFullName(r.farmer_id),
  // "created_at":r.created_at.strftime("%d/%m/%Y"),
  // "updated_at":r.updated_at.strftime("%d/%m/%Y"),

  const UsersData = {
    columns: [
      { name: "material_name", align: "left" },
      { name: "unit", align: "left" },
      { name: "buying_price", align: "left" },
      { name: "quantity", align: "left" },
      { name: "farmer", align: "left" },
      { name: "status", align: "left" },
      { name: "done_by", align: "left" },
      { name: "created_at", align: "left" },
      { name: "action", align: "left" },
    ],

    rows: completeRowsData.rows.map((item) => {
      const data = {
        material_name: <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
          <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography p={1} variant="button" fontWeight="medium">
              {item.material_name}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>,
        unit: item.unit,
        buying_price: Intl.NumberFormat().format(+item.buying_price),
        quantity: Intl.NumberFormat().format(+item.quantity),
        farmer: item.farmer,
        status: <ArgonBox display="flex" flexDirection="column">
          <ArgonBox>
            <ArgonButton style={{ fontSize: 10, padding: 5 }} color={item.order_status === "COMPLETED" ? "info" : "warning"} size="small">
              {item.order_status}
            </ArgonButton>
          </ArgonBox>

          {item.order_status === "IN TRANSIT" && (
            <ArgonBox>
              <ArgonTypography variant="button" color="text" fontWeight="medium">Driver Details</ArgonTypography>
              <p>Name: <strong>{item.driver_details.driver_name}</strong></p>
              <p>Phone: <strong>{item.driver_details.driver_phone}</strong></p>
              <p>Plate Number: <strong>{item.driver_details.plate_number}</strong></p>
              {/* <p>Details: <strong>{item.driver_details.details}</strong></p> */}
            </ArgonBox>
          )}
          {item.order_status === "COMPLETED" && (
            <ArgonBox>
              <ArgonTypography variant="button" color="text" fontWeight="medium">Driver Details</ArgonTypography>
              <p>Name: <strong>{item.driver_details.driver_name}</strong></p>
              <p>Phone: <strong>{item.driver_details.driver_phone}</strong></p>
              <p>Plate Number: <strong>{item.driver_details.plate_number}</strong></p>
              {/* <p>Details: <strong>{item.driver_details.details}</strong></p> */}
            </ArgonBox>
          )}
        </ArgonBox>,
        done_by: item.done_by,
        created_at: item.created_at,
        action: (
          <ArgonBox display="flex" flexDirection="column">
            {item.order_status === "IN TRANSIT" ? (
              <ArgonButton onClick={() => completeOrderFunction(item.id)} style={{ fontSize: 10, padding: 5 }} color="secondary" size="small">
                Complete Order
              </ArgonButton>
            ) : (
              <span>-------</span>
            )}
          </ArgonBox>
        )
      }
      return data
    }),
  };

  const { columns, rows } = UsersData;

  const getApiData = () => {
    const startData = localStorage.getItem("Materials_purchases_data")
    if (startData) {
      setApiDatas(JSON.parse(startData))
      setApiLoading(false)
      setEndLoading(false)
    }

    const url = `${API.url}get-all-materials-manunuzi?page=${page}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Materials_purchases_data", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Materials_purchases_data")
        setApiDatas(JSON.parse(finalData))

        setApiLoading(false)
        setEndLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, [page]);

  const searchSalesFunction = (search) => {
    const url = `${API.url}get-all-materials-manunuzi-by-search?search=${encode(search)}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiDatasSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const completeOrderFunction = (order_id) => {
    swal({
      title: "Hold on!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          setApiLoading(true)
          fetch(`${API.url}complete-material-in-transit?order_id=${encode(order_id.toString())}&key=${API.key}`)
            .then(res => res.json())
            .then(res => {
              getApiData()
              setApiLoading(false)
              swal(res.result, res.message, res.icon);
            })
            .catch(err => {
              setApiLoading(false)
              swal("Opps!", err.message, "warning");
            })
        } else {
          swal("Action cancelled!");
        }
      });
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">Material Purchases  | Page {page}</ArgonTypography>
                </ArgonBox>

                <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search Material..."
                    onChange={(e) => {
                      searchSalesFunction(e.target.value)
                    }}
                  />
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                  <ArgonBox p={2}>
                    <ArgonTypography fontWeight="medium">Total Amount: TZS {Intl.NumberFormat().format(completeRowsData.total_amount)}/=</ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
                <ArgonBox display="flex" p={3}>
                  <ArgonButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        if (page <= 1) {
                          setPage(1)
                          setEndLoading(false)
                        } else {
                          setPage(page - 1)
                        }
                      }, 1000);
                    }}
                  >
                    &laquo; prev
                  </ArgonButton>

                  <ArgonButton
                    style={{ marginLeft: 10 }}
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        setPage(page + 1)
                      }, 1000);
                    }}
                  >
                    next &raquo;
                  </ArgonButton>
                </ArgonBox>
                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
              </Card>
            </ArgonBox>
          </ArgonBox>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default MaterialsPurchases;
