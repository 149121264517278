import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import { modalSettings } from "constants/ModalSettins";
import { FormGroup } from "@mui/material";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";

function AccountPayable() {
  const [registerModal, setRegisterModal] = useState(false)
  const [name, setName] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [apiData, setApiDistricts] = useState({ rows: [], total_amount: 0 })
  const [apiDataSearch, setApiDistrictsSearch] = useState({ rows: [], total_amount: 0 })
  const [apiLoading, setApiLoading] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [region, setRegion] = useState("")
  const [amount, setAmount] = useState("")
  const [description, setDescription] = useState("")
  const [hasPrev, setHasPrev] = useState(false)
  const [hasNext, setHasNext] = useState(false)

  const completeRowsData = apiDataSearch.rows.length > 0 ? apiDataSearch : apiData

  const DistrictsData = {
    columns: [
      { name: "sn", align: "left" },
      { name: "amount", align: "left" },
      { name: "description", align: "left" },
      { name: "done_by", align: "left" },
      { name: "status", align: "left" },
      { name: "created_at", align: "center" },
      { name: "action", align: "center" },
    ],

    rows: completeRowsData.rows.map((item, idx) => {
      const data = {
        sn: <p style={{ padding: 10 }}>{++idx}</p>,
        amount: <ArgonTypography variant="button" fontWeight="medium">TZS {Intl.NumberFormat().format(item.amount)}</ArgonTypography>,
        description: item.description,
        done_by: item.done_by,
        status: <ArgonButton color={item.status === "UNPAID" ? "warning" : "success"} size="small">{item.status}</ArgonButton>,
        created_at: item.created_at,
        action: (
          <>
            {item.status == "UNPAID" && localStorage.getItem("role") == "ADMIN" ? (
              <>
                <ArgonButton onClick={() => approveExpenseFunction("PAID", item.id)} color={"success"}>PAY</ArgonButton>
                <ArgonButton onClick={() => approveExpenseFunction("DELETED", item.id)} style={{ marginLeft: 6 }} color={"warning"}>Remove</ArgonButton>
              </>
            ) : (
              <ArgonTypography>
                ------
              </ArgonTypography>
            )}
          </>
        )
      }
      return data
    }),
  };

  const { columns, rows } = DistrictsData;

  const approveExpenseFunction = (status, expense_id) => {
    swal({
      title: "Hold on!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          approveExpenseComplete(status, expense_id)
        } else {
          swal("Action cancelled!");
        }
      });
  }

  const approveExpenseComplete = (status, expense_id) => {
    setApiLoading(true)
    const url = `${API.url}approve-account-payable/${encode(expense_id.toString())}/${encode(status)}/${encode(localStorage.getItem("user_id"))}?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(res => {
        setRegisterModal(false)
        setApiLoading(false)
        getApiFirstData()
        setAmount("")
        setDescription("")
        swal(res.result, res.message, "success");
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const getApiFirstData = () => {
    const url = `${API.url}get-all-account-payable?page=1&status=${encode("UNPAID")}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Account_Payable_data_async_", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Account_Payable_data_async_")
        setApiDistricts(JSON.parse(finalData))
        setHasNext(true)
        setHasNext(true)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const getApiData = () => {
    const startData = localStorage.getItem("Account_Payable_data_async_")
    if (startData) {
      setApiDistricts(JSON.parse(startData))
      setApiLoading(false)
      setEndLoading(false)
    }

    const url = `${API.url}get-all-account-payable?page=${page}&status=${encode("UNPAID")}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setHasNext(data.has_next)
        setHasPrev(data.has_prev)
        localStorage.setItem("Account_Payable_data_async_", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Account_Payable_data_async_")
        setApiDistricts(JSON.parse(finalData))

        setApiLoading(false)
        setEndLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, [page]);

  const registerExpensesFunction = () => {
    if (amount === "" || description === "") {
      swal("Opps!", "Please fill all required informations", "warning");
    } else {
      setIsLoading(true)
      setTimeout(() => {
        const formData = new FormData()
        formData.append("done_by", encode(localStorage.getItem("user_id")))
        formData.append("description", description)
        formData.append("amount", encode(amount))
        fetch(`${API.url}register-account-payable?key=${API.key}`, { method: "POST", body: formData })
          .then(res => res.json())
          .then(res => {
            getApiFirstData()
            setRegisterModal(false)
            setIsLoading(false)
            if (res.result === "SUCCESS") {
              setName("")
              setRegion("")
              swal(res.result, res.message, "success")
            } else {
              swal(res.result, res.message, "warning")
            }
          })
          .catch(err => {
            setIsLoading(false)
            swal("Opps!", err.message, "warning");
          })
      }, 1000);
    }
  }

  const searchRegionFunction = (search) => {
    const url = `${API.url}get-all-account-payable-by-search?search=${encode(search)}&status=${encode("UNPAID")}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiDistrictsSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  // const handleScroll = (event) => {
  //   const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
  //   const scrollEndY = layoutMeasurement.height + contentOffset.y;
  //   const contentHeight = contentSize.height;

  //   if (scrollEndY >= contentHeight - 50) {
  //     getApiData();
  //   }
  // };

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">Account Payable</ArgonTypography>
                  <ArgonButton onClick={() => setRegisterModal(true)} color="info" size="small">
                    Add Account Payable
                  </ArgonButton>
                </ArgonBox>

                <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search by Description..."
                    onChange={(e) => {
                      searchRegionFunction(e.target.value)
                    }}
                  />
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                  <ArgonBox p={2}>
                    <ArgonTypography fontWeight="medium">Total Amount (UNPAID): TZS {Intl.NumberFormat().format(completeRowsData.total_amount)}/=</ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
                <ArgonBox display="flex" p={3}>
                  {hasPrev && (
                    <ArgonButton
                      color="primary"
                      size="small"
                      onClick={() => {
                        setEndLoading(true)
                        setTimeout(() => {
                          if (page <= 1) {
                            setPage(1)
                            setEndLoading(false)
                          } else {
                            setPage(page - 1)
                          }
                        }, 1000);
                      }}
                    >
                      &laquo; prev
                    </ArgonButton>
                  )}

                  {hasNext && (
                    <ArgonButton
                      style={{ marginLeft: 10 }}
                      color="primary"
                      size="small"
                      onClick={() => {
                        setEndLoading(true)
                        setTimeout(() => {
                          setPage(page + 1)
                        }, 1000);
                      }}
                    >
                      next &raquo;
                    </ArgonButton>
                  )}
                </ArgonBox>
                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
              </Card>
            </ArgonBox>
          </ArgonBox>


          <Modal
            isOpen={registerModal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={modalSettings}
            contentLabel="Register Account
            Payable"
          >
            <div style={{ float: "right" }}>
              <ArgonButton color="warning" onClick={() => setRegisterModal(false)}>close</ArgonButton>
            </div>
            <br />
            <h2>Add Account
              Payable</h2>

            <FormGroup>
              <ArgonTypography variant="button" color="text" fontWeight="medium">Amount</ArgonTypography>
              <ArgonInput onChange={(e) => setAmount(e.target.value)} value={amount} placeholder="Amount" />
            </FormGroup>
            <br />
            <FormGroup>
              <ArgonTypography variant="button" color="text" fontWeight="medium">Description</ArgonTypography>
              <textarea style={{ padding: 10 }} rows={10} onChange={(e) => setDescription(e.target.value)} value={description} placeholder="Description" ></textarea>
            </FormGroup>
            <br />

            {isLoading === true ? (
              <Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />
            ) : (
              <ArgonButton onClick={() => registerExpensesFunction()} color="primary">Add</ArgonButton>
            )}
          </Modal>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default AccountPayable;
