import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import { modalSettings } from "constants/ModalSettins";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import { SelectStyle } from "constants/SelectStyle";
import ArgonAvatar from "components/ArgonAvatar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

function Shops() {
  const [registerModal, setRegisterModal] = useState(false)
  const [lastName, setLastName] = useState("")
  const [middleName, setMiddleName] = useState("")
  const [nida, setNida] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [role, setRole] = useState("")
  const [address, setAddress] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [apiWards, setApiWards] = useState([])
  const [apiWardsSearch, setApiWardsSearch] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [selectedUpdate, setSelectUpdate] = useState("")
  const [profileModal, setProfileModal] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [apiRegions, setApiRegions] = useState([])
  const [region, setRegion] = useState("")
  const [district, setDistrict] = useState("")
  const [ward, setWard] = useState("")
  const [districtsData, setDistrictsData] = useState([])
  const [wardsData, setWardsData] = useState([])
  const [shopName, setShopName] = useState("")
  const [shopContactPerson, setshopContactPerson] = useState("")
  const [shopPhoneNumber, setshopPhoneNumber] = useState("")
  const [shopRegion, setshopRegion] = useState("")
  const [shopDistrict, setshopDistrict] = useState("")
  const [shopWard, setshopWard] = useState("")
  const [shopPhoto, setshopPhoto] = useState("")
  const [shopLatitude, setshopLatitude] = useState("")
  const [shopLongitude, setshopLongitude] = useState("")
  const [shopType, setShopType] = useState("")

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const getRegionsData = () => {
    const startData = localStorage.getItem("regions_data_async")
    if (startData) {
      setApiRegions(JSON.parse(startData))
      setApiLoading(false)
    }

    const url = `${API.url}get-all-regions?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("regions_data_async", JSON.stringify(data.data))
        const finalData = localStorage.getItem("regions_data_async")
        setApiRegions(JSON.parse(finalData))
      })
      .catch(err => {
        swal("Opps!", err.message, "warning");
      })
  }

  const getRegionDistricts = (region_id) => {
    setshopRegion(region_id)
    const url = `${API.url}get-districts-by-region/${encode(region_id)}?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setDistrictsData(data.data)
      })
      .catch(err => {
        swal("Opps!", err.message, "warning");
      })
  }

  const getDistrictWards = (district_id) => {
    setshopDistrict(district_id)
    const url = `${API.url}get-wards-by-district/${encode(district_id)}?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setWardsData(data.data)
      })
      .catch(err => {
        swal("Opps!", err.message, "warning");
      })
  }

  const completeRowsData = apiWardsSearch.length > 0 ? apiWardsSearch : apiWards

  const WardsData = {
    columns: [
      // { name: "user_id", align: "left" },
      { name: "name", align: "left" },
      { name: "contact_person", align: "left" },
      { name: "phone_number", align: "left" },
      { name: "region", align: "left" },
      { name: "district", align: "left" },
      // { name: "ward", align: "left" },
      { name: "registered_by", align: "left" },
      { name: "created_at", align: "center" },
      { name: "action", align: "center" },
    ],

    rows: completeRowsData.map((item, idx) => {
      const data = {
        name: <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
          <ArgonBox mr={2}>
            <ArgonAvatar src={item.photo} alt={item.name} size="sm" variant="rounded" />
          </ArgonBox>
          <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography variant="button" fontWeight="medium">
              {`${item.name}`}
            </ArgonTypography>
            {/* <ArgonTypography variant="caption" color="secondary">
              {item.phone_number}
            </ArgonTypography> */}
          </ArgonBox>
        </ArgonBox>,
        contact_person: item.contact_person,
        phone_number: item.phone_number,
        region: item.region,
        district: item.district,
        // ward: item.ward,
        registered_by: item.registered_by,
        created_at: item.created_at,

        action: (
          <>
            <ArgonButton
              color="secondary"
              size="small"
              onClick={() => {
                setSelectUpdate(item.id)
                setShopName(item.name)
                setshopContactPerson(item.contact_person)
                setshopPhoneNumber(item.phone_number)
                setshopRegion(item.region_id)
                setshopDistrict(item.district_id)
                setshopWard(item.ward_id)
                setshopPhoto(item.photo)
                setShopType(item.shop_type)
                setRegisterModal(true)
              }}
            >
              Edit
            </ArgonButton>

            <ArgonButton
              style={{ marginLeft: 6 }}
              color="info"
              size="small"
              onClick={() => {
                setSelectUpdate(item.id)
                setShopName(item.name)
                setshopContactPerson(item.contact_person)
                setshopPhoneNumber(item.phone_number)
                setshopRegion(item.region)
                setshopDistrict(item.district)
                setshopWard(item.ward)
                setshopPhoto(item.photo)
                setshopLatitude(item.latitude)
                setshopLongitude(item.longitude)
                setShopType(item.shop_type)
                setProfileModal(true)
              }}
            >
              View
            </ArgonButton>
          </>
        ),
      }
      return data
    }),
  };

  const { columns, rows } = WardsData;

  const getApiData = () => {
    const startData = localStorage.getItem("All_Shops_data_async")
    if (startData) {
      setApiWards(JSON.parse(startData))
      setApiLoading(false)
      setEndLoading(false)
    }

    const url = `${API.url}get-all-shops?page=${page}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("All_Shops_data_async", JSON.stringify(data.data))

        const finalData = localStorage.getItem("All_Shops_data_async")
        setApiWards(JSON.parse(finalData))

        setApiLoading(false)
        setEndLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getRegionsData()
    getApiData()
  }, [page]);

  const updateShopFunction = () => {
    if (shopName === "" || shopRegion === "" || shopDistrict === "" || shopWard === "" || shopContactPerson === "" || shopPhoneNumber === "" || shopType === "") {
      swal("Opps!", "Please fill all required informations", "warning");
    } else {
      setIsLoading(true)
      setTimeout(() => {

        const formData = new FormData()
        formData.append("shop_id", encode(selectedUpdate))
        formData.append("name", encode(shopName))
        formData.append("region", encode(shopRegion))
        formData.append("district", encode(shopDistrict))
        formData.append("ward", encode(shopWard))
        formData.append("contact_person", encode(shopContactPerson))
        formData.append("phone_number", encode(shopPhoneNumber))
        formData.append("last_name", encode(lastName))
        formData.append("nida", encode(nida))
        formData.append("phone_number", encode(phoneNumber))
        formData.append("shop_type", encode(shopType))
        fetch(`${API.url}update-shop?key=${API.key}`, { method: "PUT", body: formData })
          .then(res => res.json())
          .then(res => {
            // setPage(0)
            getApiData()
            clearHooks()
            setRegisterModal(false)
            setIsLoading(false)
            if (res.result === "SUCCESS") {
              swal(res.result, res.message, "success")
            } else {
              swal(res.result, res.message, "warning")
            }
          })
          .catch(err => {
            setIsLoading(false)
            swal("Opps!", err.message, "warning");
          })
      }, 1000);
    }
  }

  const searchUsersFunction = (search) => {
    const url = `${API.url}get-all-shops-by-search?search=${encode(search)}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiWardsSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const clearHooks = () => {
    setSelectUpdate("")
    setShopName("")
    setshopContactPerson("")
    setshopPhoneNumber("")
    setshopRegion("")
    setshopDistrict("")
    setshopWard("")
    setshopPhoto("")
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">Clients</ArgonTypography>
                  {/* <ArgonButton onClick={() => setRegisterModal(true)} color="info" size="small">
                    Add Shop
                  </ArgonButton> */}
                </ArgonBox>

                <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search Here..."
                    onChange={(e) => {
                      searchUsersFunction(e.target.value)
                    }}
                  />
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </ArgonBox>
                <ArgonBox display="flex" p={3}>
                  <ArgonButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        if (page <= 1) {
                          setPage(1)
                          setEndLoading(false)
                        } else {
                          setPage(page - 1)
                        }
                      }, 1000);
                    }}
                  >
                    &laquo; prev
                  </ArgonButton>

                  <ArgonButton
                    style={{ marginLeft: 10 }}
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        setPage(page + 1)
                      }, 1000);
                    }}
                  >
                    next &raquo;
                  </ArgonButton>
                </ArgonBox>
                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
              </Card>
            </ArgonBox>
          </ArgonBox>


          <Modal
            isOpen={profileModal}
            style={modalSettings}
            shouldCloseOnEsc={true}
            preventScroll={false}
            role="dialog"
          >
            <div style={{ float: "right" }}>
              <ArgonButton
                color="warning"
                onClick={() => {
                  clearHooks()
                  setProfileModal(false)
                }}
              >close</ArgonButton>
            </div>

            <ArgonBox>
              <ArgonBox display="flex">
                <div style={{ width: "40%", textAlign: "center", alignItems: "center", alignContent: "center", justifyContent: "center" }} >
                  <ArgonAvatar src={shopPhoto} alt={"Image"} size="extral" variant="rounded" style={{ borderRadius: 100 }} />
                  <ArgonTypography variant="h5" fontWeight="medium">ID: {selectedUpdate}</ArgonTypography>
                </div>
                <div style={{ width: "60%" }}>
                  <ArgonBox display="flex" justifyContent="space-between">
                    <div style={{ width: "100%" }}>
                      <ArgonTypography variant="h5" fontWeight="medium">Shop Information</ArgonTypography>
                      <hr />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Shop Name: {shopName}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Shop Type: {shopType}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Contact Person: {shopContactPerson}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Phone Number: {shopPhoneNumber}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Region: {shopRegion}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">District: {shopDistrict}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Latitude: {shopLatitude}</ArgonTypography>
                      <br />
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Longitude: {shopLongitude}</ArgonTypography>
                      <br />
                      <ArgonButton
                        style={{ marginLeft: 6 }}
                        color="secondary"
                        size="small"
                        onClick={() => {
                          window.open(`https://maps.google.com/?q=${shopLatitude},${shopLongitude}`, `_blank`)
                        }}
                      >
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                        View in Map
                      </ArgonButton>
                    </div>
                  </ArgonBox>
                </div>
              </ArgonBox>
            </ArgonBox>
          </Modal>


          <Modal
            isOpen={registerModal}
            style={modalSettings}
            // contentLabel="Register Shop"
            shouldCloseOnEsc={true}
            preventScroll={false}
            role="dialog"
          >
            <div style={{ float: "right" }}>
              <ArgonButton
                color="warning"
                onClick={() => {
                  setSelectUpdate("")
                  setRegisterModal(false)
                }}
              >close</ArgonButton>
            </div>
            <br />
            <h2>{selectedUpdate === "" ? "Register Shop" : "Update Shop"}</h2>
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Region</ArgonTypography>
                <select value={shopRegion} onChange={(e) => getRegionDistricts(e.target.value)} autoFocus onFocus={(e) => getRegionDistricts(e.target.value)} onClick={(e) => getRegionDistricts(e.target.value)} style={SelectStyle}>
                  <option>--Select Region--</option>
                  {apiRegions.map((item, idx) =>
                    <option key={idx} value={item.id}>{item.name}</option>
                  )}
                </select>
              </div>
              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">District</ArgonTypography>
                <select value={shopDistrict} onChange={(e) => getDistrictWards(e.target.value)} onClick={(e) => getDistrictWards(e.target.value)} onBlur={(e) => getDistrictWards(e.target.value)} style={SelectStyle}>
                  <option>--Select District--</option>
                  {districtsData.map((item, idx) =>
                    <option key={idx} value={item.id}>{item.name}</option>
                  )}
                </select>
              </div>
              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Ward</ArgonTypography>
                <select value={shopWard} onClick={(e) => setWard(e.target.value)} onChange={(e) => setshopWard(e.target.value)} style={SelectStyle}>
                  <option>--Select Ward--</option>
                  {wardsData.map((item, idx) =>
                    <option key={idx} value={item.id}>{item.name}</option>
                  )}
                </select>
              </div>
            </ArgonBox>
            <br />
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Shop Name</ArgonTypography>
                <ArgonInput onChange={(e) => setShopName(e.target.value)} value={shopName} placeholder="Enter Shop Name" />
              </div>
              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Contact Person</ArgonTypography>
                <ArgonInput onChange={(e) => setshopContactPerson(e.target.value)} value={shopContactPerson} placeholder="Enter Contact Person" />
              </div>
              <div style={{ width: "33%" }}>
                <ArgonTypography variant="button" color="text" fontWeight="medium">Phone Number</ArgonTypography>
                <ArgonInput
                  onChange={(e) => {
                    setshopPhoneNumber(e.target.value)
                  }}
                  value={shopPhoneNumber}
                  placeholder="Enter Phone Number"
                />
              </div>
            </ArgonBox>
            <br />
            <div style={{ width: "33%" }}>
              <ArgonTypography variant="button" color="text" fontWeight="medium">Shop Type</ArgonTypography>
              <select value={shopType} onClick={(e) => setWard(e.target.value)} onChange={(e) => setShopType(e.target.value)} style={SelectStyle}>
                <option value={shopType}>{shopType}</option>
                <option value={"SHOP"}>SHOP</option>
                <option value={"MIN_MARKET"}>MIN MARKET</option>
                <option value={"SUPER_MARKET"}>SUPER MARKET</option>
              </select>
            </div>
            <br />
            {isLoading === true ? (
              <Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />
            ) : (
              <ArgonButton onClick={() => updateShopFunction()} color="primary">Update</ArgonButton>
            )}
          </Modal>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default Shops;
