import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import ArgonAvatar from "components/ArgonAvatar";
import { useNavigate } from "react-router-dom";

function MaterialsUsageHistory() {
  const [apiWards, setApiWards] = useState([])
  const [apiWardsSearch, setApiWardsSearch] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const completeRowsData = apiWardsSearch.length > 0 ? apiWardsSearch : apiWards

  const WardsData = {
    columns: [
      { name: "name", align: "left" },
      { name: "quantity", align: "left" },
      { name: "details", align: "left" },
      { name: "done_by", align: "left" },
      { name: "created_at", align: "center" },
    ],

    rows: completeRowsData.map((item, idx) => {
      const data = {
        name: <ArgonBox display="flex" alignItems="center" px={2} py={0.5}>
          <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography variant="button" fontWeight="medium">
              {`${item.name}`}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>,
        quantity: Intl.NumberFormat().format(item.quantity),
        details: item.details,
        done_by: <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
          <ArgonBox mr={2}>
            <ArgonAvatar src={item.user_photo} alt={`${item.done_by}`} size="sm" variant="rounded" />
          </ArgonBox>
          <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography variant="button" fontWeight="medium">
              {`${item.done_by}`}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>,
        created_at: item.created_at,
      }
      return data
    }),
  };

  const { columns, rows } = WardsData;

  const getApiData = () => {
    const startData = localStorage.getItem("Materials_usage_history_async_data")
    if (startData) {
      setApiWards(JSON.parse(startData))
      setApiLoading(false)
      setEndLoading(false)
    }

    const url = `${API.url}get-used-processing-materials-history?page=${page}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Materials_usage_history_async_data", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Materials_usage_history_async_data")
        setApiWards(JSON.parse(finalData))

        setApiLoading(false)
        setEndLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, [page]);

  const searchUsersFunction = (search) => {
    const url = `${API.url}get-used-processing-materials-history-by-search?search=${encode(search)}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiWardsSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">Materials Usage History Table | Page {page}</ArgonTypography>
                  {/* <ArgonButton onClick={() => setRegisterModal(true)} color="info" size="small">
                    Add Farmer
                  </ArgonButton> */}
                </ArgonBox>

                <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search Here..."
                    onChange={(e) => {
                      searchUsersFunction(e.target.value)
                    }}
                  />
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </ArgonBox>
                <ArgonBox display="flex" p={3}>
                  <ArgonButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        if (page <= 1) {
                          setPage(1)
                          setEndLoading(false)
                        } else {
                          setPage(page - 1)
                        }
                      }, 1000);
                    }}
                  >
                    &laquo; prev
                  </ArgonButton>

                  <ArgonButton
                    style={{ marginLeft: 10 }}
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        setPage(page + 1)
                      }, 1000);
                    }}
                  >
                    next &raquo;
                  </ArgonButton>
                </ArgonBox>
                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
              </Card>
            </ArgonBox>
          </ArgonBox>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default MaterialsUsageHistory;
