import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import { useNavigate } from "react-router-dom";
import ArgonAvatar from "components/ArgonAvatar";
import { SelectStyle } from "constants/SelectStyle";

function ClientsOrders() {
  const [apiDatas, setApiDatas] = useState([])
  const [apiDataSearch, setApiDatasSearch] = useState({ rows: [], total_amount: 0, sum_total: 0, total_quantity: 0 })
  const [apiLoading, setApiLoading] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalQuantity, setTotalQuantity] = useState(0)
  const [status, setStatus] = useState("PENDING")
  const [clients, setClients] = useState([])
  const [users, setUsers] = useState([])
  const [salesPerson, setSalesPerson] = useState("")
  const [customer, setCustomer] = useState("")
  const [startDate, setstartDate] = useState('');
  const [endDate, setendDate] = useState('');

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const getUsers = () => {
    const firstData = localStorage.getItem("Users_data_to_filter_sales_async")
    if (firstData) {
      setUsers(JSON.parse(firstData))
    }

    const url = `${API.url}get-all-sales-person?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Users_data_to_filter_sales_async", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Users_data_to_filter_sales_async")
        if (finalData) {
          setUsers(JSON.parse(finalData))
        }
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const getClients = () => {
    const firstData = localStorage.getItem("Clients_data_to_filter_sales_async")
    if (firstData) {
      setClients(JSON.parse(firstData))
    }

    const url = `${API.url}get-all-shops-not-paginated?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Clients_data_to_filter_sales_async", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Clients_data_to_filter_sales_async")
        if (finalData) {
          setClients(JSON.parse(finalData))
        }
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const completeRowsData = apiDataSearch.length > 0 ? apiDataSearch : apiDatas

  const UsersData = {
    columns: [
      { name: "order_id", align: "left" },
      { name: "total", align: "left" },
      { name: "client_name", align: "left" },
      { name: "seller_name", align: "left" },
      { name: "status", align: "left" },
      { name: "created_at", align: "left" },
    ],

    rows: completeRowsData.map((item) => {
      const data = {
        order_id: <ArgonBox alignItems="center" px={1} py={0.5}>
          <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography p={1} variant="button" fontWeight="medium">
              {item.order_id}
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox>
            {item.products.map((pro, ix) =>
              <ArgonBox key={ix} display="flex" alignItems="center" px={1} py={0.5}>
                <ArgonBox mr={2}>
                  <ArgonAvatar src={pro.product_photo} alt={`${pro.product_name}`} size="sm" variant="rounded" />
                </ArgonBox>
                <ArgonBox display="flex" flexDirection="column">
                  <span>
                    {`${pro.product_name} ${pro.unit} (${pro.quantity} PCS)`}
                  </span>
                </ArgonBox>
              </ArgonBox>
            )}
          </ArgonBox>
        </ArgonBox>,
        total: <ArgonTypography variant="button">TZS: {Intl.NumberFormat().format(+item.product_total_price)}</ArgonTypography>,
        client_name: <ArgonTypography variant="button">{item.buyer} ({item.buyer_phone}) <br />{item.buyer_location}</ArgonTypography>,
        seller_name: item.seller_name,
        status: <ArgonButton color={item.status === "SOLD" ? "success" : "warning"} size="small">{item.status}</ArgonButton>,
        created_at: item.created_at,
      }
      return data
    }),
  };

  const { columns, rows } = UsersData;

  const getApiData = () => {
    const startData = localStorage.getItem("Clients_Orders_data_async_")
    if (startData) {
      setApiDatas(JSON.parse(startData))
      setApiLoading(false)
      setEndLoading(false)
    }

    const url = `${API.url}get-seller-clients-orders?status=${encode(status)}&page=${page}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setTotalAmount(data.total_amount)
        setTotalQuantity(data.total_quantity)
        localStorage.setItem("Clients_Orders_data_async_", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Clients_Orders_data_async_")
        setApiDatas(JSON.parse(finalData))

        setApiLoading(false)
        setEndLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    getUsers()
    getClients()
    setApiLoading(true)
    getApiData()
  }, [page, status]);

  const getFilterData = () => {
    if (status === "" || customer === "" || startDate === "" || endDate === "") {
      swal("Opps!", "Please Select all required information", "warning");
    } else {
      setApiLoading(true)
      const url = `${API.url}get-clients-orders-filter?status=${encode(status)}&page=${page}&key=${API.key}&customer_id=${encode(customer)}&seller_id=${encode(salesPerson)}&start_date=${encode(startDate)}&end_date=${encode(endDate)}`
      fetch(url)
        .then(res => res.json())
        .then(data => {
          setTotalAmount(data.total_amount)
          setTotalQuantity(data.total_quantity)
          setApiDatas(data.data)

          setApiLoading(false)
          setEndLoading(false)
        })
        .catch(err => {
          setApiLoading(false)
          swal("Opps!", err.message, "warning");
        })
    }
  }

  const searchSalesFunction = (search) => {
    const url = `${API.url}get-seller-clients-orders-by-search?status=${encode(status)}&search=${encode(search)}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setTotalAmount(data.total_amount)
        setTotalQuantity(data.total_quantity)
        setApiDatasSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">New Clients Orders</ArgonTypography>

                  {startDate !== "" && endDate !== "" && customer !== "" && (
                    <ArgonBox
                      onClick={() => {
                        if (salesPerson) {
                          window.open(`${API.url}get-clients-orders-filter-pdf?status=${encode(status)}&page=${page}&key=${API.key}&customer_id=${encode(customer)}&seller_id=${encode(salesPerson)}&start_date=${encode(startDate)}&end_date=${encode(endDate)}`, `_target`)
                        } else {
                          window.open(`${API.url}get-clients-orders-filter-pdf?status=${encode(status)}&page=${page}&key=${API.key}&customer_id=${encode(customer)}&start_date=${encode(startDate)}&end_date=${encode(endDate)}`, `_target`)
                        }
                      }}
                      display="flex" flexDirection="column">
                      <ArgonButton style={{ fontSize: 10, padding: 5 }} color={"info"} size="small">
                        Preview PDF
                      </ArgonButton>
                    </ArgonBox>
                  )}
                </ArgonBox>

                <ArgonBox flexDirection="row" display="flex" justifyContent="space-between" style={{}}>
                  <ArgonBox p={1} style={{ width: "33%" }}>
                    <ArgonTypography variant="button" color="text" fontWeight="medium">Status *</ArgonTypography>
                    <select value={status} onChange={(e) => setStatus(e.target.value)} onClick={(e) => setStatus(e.target.value)} style={SelectStyle} autoFocus>
                      <option value={status}>{status}</option>
                      <option value={"PENDING"}>PENDING</option>
                      <option value={"SOLD"}>SOLD</option>
                      <option value={"CANCELED"}>CANCELED</option>
                    </select>
                  </ArgonBox>

                  <ArgonBox p={1} style={{ width: "33%" }}>
                    <ArgonTypography variant="button" color="text" fontWeight="medium">Client *</ArgonTypography>
                    <select value={customer} onChange={(e) => setCustomer(e.target.value)} style={SelectStyle} >
                      <option value={""}>--Select Client--</option>
                      {clients.map((item, idx) =>
                        <option key={idx} value={item.id}>{item.name}</option>
                      )}
                    </select>
                  </ArgonBox>

                  <ArgonBox p={1} style={{ width: "33%" }}>
                    <ArgonTypography variant="button" color="text" fontWeight="medium">Sales Person</ArgonTypography>
                    <select value={salesPerson} onChange={(e) => setSalesPerson(e.target.value)} style={SelectStyle} >
                      <option value={""}>--All--</option>
                      {users.map((item, idx) =>
                        <option key={idx} value={item.id}>{item.first_name} {item.middle_name} {item.last_name}</option>
                      )}
                    </select>
                  </ArgonBox>
                </ArgonBox>

                <ArgonBox flexDirection="row" display="flex" justifyContent="space-between" style={{}}>
                  <ArgonBox p={1} style={{ width: "33%" }}>
                    <ArgonTypography variant="button" color="text" fontWeight="medium">Starting Date *</ArgonTypography>
                    <ArgonInput
                      placeholder="Start Date"
                      value={startDate}
                      onChange={(e) => {
                        setstartDate(e.target.value)
                      }}
                      type="date"
                    />
                  </ArgonBox>

                  <ArgonBox p={1} style={{ width: "33%" }}>
                    <ArgonTypography variant="button" color="text" fontWeight="medium">End Date *</ArgonTypography>
                    <ArgonInput
                      placeholder="End Date"
                      value={endDate}
                      onChange={(e) => {
                        setendDate(e.target.value)
                      }}
                      type="date"
                    />
                  </ArgonBox>

                  <ArgonBox p={1} style={{ width: "10%" }}>
                    <ArgonTypography variant="button" color="text" fontWeight="medium">-------</ArgonTypography>

                    <ArgonButton onClick={() => { getFilterData() }} color="primary">Filter</ArgonButton>
                  </ArgonBox>
                </ArgonBox>

                <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search by Client or Order ID..."
                    onChange={(e) => {
                      searchSalesFunction(e.target.value)
                    }}
                  />
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                  <ArgonBox p={2}>
                    <ArgonTypography fontWeight="medium">Total Items: {Intl.NumberFormat().format(totalQuantity)}</ArgonTypography>
                    <ArgonTypography fontWeight="medium">Total Amount: TZS {Intl.NumberFormat().format(totalAmount)}/=</ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
                <ArgonBox display="flex" p={3}>
                  <ArgonButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        if (page <= 1) {
                          setPage(1)
                          setEndLoading(false)
                        } else {
                          setPage(page - 1)
                        }
                      }, 1000);
                    }}
                  >
                    &laquo; prev
                  </ArgonButton>

                  <ArgonButton
                    style={{ marginLeft: 10 }}
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        setPage(page + 1)
                      }, 1000);
                    }}
                  >
                    next &raquo;
                  </ArgonButton>
                </ArgonBox>
                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
              </Card>
            </ArgonBox>
          </ArgonBox>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default ClientsOrders;
