import Grid from "@mui/material/Grid";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import { useState } from "react";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import { AppColor } from "constants/AppColors";
import ArgonButton from "components/ArgonButton";
import Loader from "react-js-loader";
import swal from "sweetalert";
import { encode, decode } from 'base-64';
import API from "Api/Api";
import { useNavigate } from "react-router-dom";

// Data

// Images
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg";

function Overview() {
  const [password, setPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const updatePasswordFunction = () => {
    if (password === "" || newPassword === "" || confirmNewPassword === "") {
      swal("Opps!", "Please fill all required informations", "warning");
    } else if (newPassword !== confirmNewPassword) {
      swal("Opps!", "Password does not match", "warning");
    } else {
      setIsLoading(true)
      const formData = new FormData()
      formData.append("user_id", encode(localStorage.getItem("user_id")))
      formData.append("password", encode(password))
      formData.append("passwordTwo", encode(newPassword))
      fetch(`${API.url}update_user_password?key=${API.key}`, { method: "POST", body: formData })
        .then(res => res.json())
        .then(res => {
          setIsLoading(false)
          swal(res.result, res.message, res.icon);
        })
        .catch(err => {
          setIsLoading(false)
          swal("Opps!", err.message, "warning");
        })
    }
  }

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.info.main, 0.6),
            rgba(gradients.info.state, 0.6)
          )}, url(${bgImage})`,
        backgroundPositionY: "50%",
      }}
    >
      <Header />
      <ArgonBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={4}>
            <PlatformSettings />
            <hr />
            <div>
              <h3>Update Password</h3>
              <ArgonTypography variant="button" color="text" fontWeight="medium">Old Password</ArgonTypography>
              <ArgonInput onChange={(e) => setPassword(e.target.value)} value={password} type="password" placeholder="Enter Password" />

              <ArgonTypography variant="button" color="text" fontWeight="medium">New Password</ArgonTypography>
              <ArgonInput onChange={(e) => setNewPassword(e.target.value)} value={newPassword} type="password" placeholder="Enter New Password" />

              <ArgonTypography variant="button" color="text" fontWeight="medium">Confirm New Password</ArgonTypography>
              <ArgonInput onChange={(e) => setConfirmNewPassword(e.target.value)} type="password" value={confirmNewPassword} placeholder="Confirm New Password" />

              <div style={{ marginTop: 6 }}>
                {isLoading === true ? (
                  <Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />
                ) : (
                  <ArgonButton onClick={() => updatePasswordFunction()} color="primary">Update</ArgonButton>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} xl={8}>
            <ProfileInfoCard
              title="profile information"
              description={`Hi, I'm ${localStorage.getItem("username")}, Decisions: If you can't decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).`}
              info={{
                fullName: localStorage.getItem("username"),
                region: localStorage.getItem("region"),
                district: localStorage.getItem("district"),
                location: localStorage.getItem("ward"),
              }}
              social={[
                {
                  link: "https://sandc.co.tz",
                  icon: <FacebookIcon />,
                  color: "facebook",
                },
                {
                  link: "https://sandc.co.tz",
                  icon: <TwitterIcon />,
                  color: "twitter",
                },
                {
                  link: "https://sandc.co.tz",
                  icon: <InstagramIcon />,
                  color: "instagram",
                },
              ]}
              action={{ route: "", tooltip: "Edit Profile" }}
            />
          </Grid>
        </Grid>
      </ArgonBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
