export const userRoles = [
  { "name": "Sales Person", "key": "SELLER" },//Manage Sales
  { "name": "Regional Store Keeper", "key": "REGIONAL_STORE" },
  { "name": "Rural Procurement", "key": "RURAL_PROCUREMENT" },
  { "name": "Accountant", "key": "ACCOUNTANT" },
  { "name": "Manager / HR", "key": "MANAGER" },
  { "name": "Head of Sales", "key": "HEAD_OF_SALES" },
  { "name": "Adminstrator", "key": "ADMIN" },
  { "name": "Operation / Main Store", "key": "MAIN_STORE" },
]

export const months = [
  { "name": "January", "key": "JANUARY" },
  { "name": "February", "key": "FEBRUARY" },
  { "name": "March", "key": "MARCH" },
  { "name": "April", "key": "APRIL" },
  { "name": "May", "key": "MAY" },
  { "name": "June", "key": "JUNE" },
  { "name": "July", "key": "JULY" },
  { "name": "August", "key": "AUGUST" },
  { "name": "September", "key": "SEPTEMBER" },
  { "name": "October", "key": "OCTOBER" },
  { "name": "November", "key": "NOVEMBER" },
  { "name": "December", "key": "DECEMBER" },
]

export const todayDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`

export const curentMonthYear = `${new Date().getFullYear()}-${new Date().getMonth() + 1}`


const month = new Date().getMonth() + 1

function _currentMonth() {
  if (month == 1) {
    return "January"
  } else if (month == 2) {
    return "February"
  } else if (month == 3) {
    return "March"
  } else if (month == 4) {
    return "April"
  } else if (month == 5) {
    return "May"
  } else if (month == 6) {
    return "June"
  } else if (month == 7) {
    return "July"
  } else if (month == 8) {
    return "August"
  } else if (month == 9) {
    return "September"
  } else if (month == 10) {
    return "October"
  } else if (month == 11) {
    return "November"
  } else if (month == 12) {
    return "December"
  }
}

export const currentMonth = _currentMonth()


export const userRolesJson = {
  "SELLER": "Sales Person",
  "REGIONAL_STORE": "Regional Store Keeper",
  "RURAL_PROCUREMENT": "Rural Procurement",
  "ACCOUNTANT": "Accountant",
  "MANAGER": "Manager / HR",
  "HEAD_OF_SALES": "Head of Sales",
  "ADMIN": "Adminstrator",
  "MAIN_STORE": "Operation / Main Store",
}


export const ainaZaMalipo = [
  {
    "name": "Cash",
    "key": "CASH",
  },
  {
    "name": "Lipa Namba",
    "key": "LIPA-NUMBER",
  },
  {
    "name": "Benki",
    "key": "BANK",
  },
  {
    "name": "Mkopo",
    "key": "CREDIT",
  },
  {
    "name": "Mitandao ya Simu",
    "key": "MOBILE-WALLET",
  },
]