import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import ArgonAvatar from "components/ArgonAvatar";
import { SelectStyle } from "constants/SelectStyle";
import { months } from "constants/StaticDatas";
import PayRoll from "layouts/payroll";
import AccountantPayroll from "layouts/payrollAccountant";
import AdminPayroll from "layouts/payrollAdmin";
import { useNavigate } from "react-router-dom";

function PayRollMainRoot() {
  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  return (
    <>
      {(() => {
        if (localStorage.getItem("role") === "MANAGER") {
          return <PayRoll />
        } else if (localStorage.getItem("role") === "ACCOUNTANT") {
          return <AccountantPayroll />
        } else if (localStorage.getItem("role") === "ADMIN") {
          return <AdminPayroll />
        }
      })()}
    </>
  );
}

export default PayRollMainRoot;
