import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import ArgonAvatar from "components/ArgonAvatar";
import { curentMonthYear } from "constants/StaticDatas";
import typography from "assets/theme/base/typography";
import { useNavigate } from "react-router-dom";
import { currentMonth } from "constants/StaticDatas";

function MonthlySales() {
  const [apiDatas, setApiDatas] = useState({ rows: [], total_amount: 0, sum_total: 0, total_quantity: 0, totalSumary: [] })
  const [apiDataSearch, setApiDatasSearch] = useState({ rows: [], total_amount: 0, sum_total: 0, total_quantity: 0 })
  const [apiLoading, setApiLoading] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [graphData, setGraphData] = useState({})
  const { size } = typography;

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const completeRowsData = apiDataSearch.rows.length > 0 ? apiDataSearch : apiDatas

  const UsersData = {
    columns: [
      // { name: "user_id", align: "left" },
      { name: "product", align: "left" },
      { name: "unit", align: "left" },
      { name: "selling_price", align: "left" },
      { name: "payment_method", align: "left" },
      { name: "quantity", align: "left" },
      { name: "total", align: "left" },
      { name: "customer_name", align: "left" },
      { name: "seller_name", align: "left" },
      { name: "created_at", align: "left" },
      { name: "proof_of_payment", align: "left" },
    ],

    rows: completeRowsData.rows.map((item) => {
      const data = {
        product: <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
          <ArgonBox mr={2}>
            <ArgonAvatar src={item.photo} alt={item.product_name} size="sm" variant="rounded" />
          </ArgonBox>
          <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography variant="button" fontWeight="medium">
              {item.product_name}
            </ArgonTypography>
            <ArgonTypography variant="caption" color="secondary">
              #Order ID: {item.order_id}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>,
        unit: item.unit,
        selling_price: Intl.NumberFormat().format(+item.selling_price),
        payment_method: item.payment_method,
        quantity: Intl.NumberFormat().format(+item.quantity),
        total: Intl.NumberFormat().format(+item.selling_price * +item.quantity),
        pay_date: item.created_at,
        customer_name: item.buyer,
        seller_name: item.seller_name,
        created_at: item.created_at,
        proof_of_payment: (
          <a href={item.pay_receipt} target="_blank"><img src={item.pay_receipt} width={100} height={80} style={{ border: "solid 1px lightgrey", borderRadius: 4 }} /></a>
        )
      }
      return data
    }),
  };

  const { columns, rows } = UsersData;

  const getApiData = () => {
    const startData = localStorage.getItem("Monthly_Sales_report_data_async_")
    if (startData) {
      setApiDatas(JSON.parse(startData))
      const labels = []
      const datas = []
      JSON.parse(startData).rows.forEach(e => {
        labels.push(e.product_name)
        datas.push(+e.selling_price * +e.quantity)
      });
      const data = {
        labels: labels,
        datasets: [
          {
            label: "TZS",
            color: "info",
            data: datas,
          },
        ],
      };
      setGraphData(data)
      setApiLoading(false)
      setEndLoading(false)
    }

    const url = `${API.url}get-monthly-sales?page=${page}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Monthly_Sales_report_data_async_", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Monthly_Sales_report_data_async_")
        setApiDatas(JSON.parse(finalData))
        const labels_ = []
        const datas_ = []
        JSON.parse(finalData).rows.forEach(e => {
          labels_.push(e.product_name)
          datas_.push(+e.selling_price * +e.quantity)
        });
        const graph_datas = {
          labels: labels_,
          datasets: [
            {
              label: "TZS",
              color: "info",
              data: datas_,
            },
          ],
        };
        setGraphData(graph_datas)
        setApiLoading(false)
        setEndLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }
  console.log(graphData);

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, [page]);

  const searchSalesFunction = (search) => {
    const url = `${API.url}get-date-range-sales-by-search/${encode(curentMonthYear)}/${encode(curentMonthYear)}?search=${encode(search)}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiDatasSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">Monthly Sales ({currentMonth})</ArgonTypography>

                  <ArgonBox
                    onClick={() => {
                      window.open(`${API.url}get-monthly-sales-pdf?key=${API.key}`, `_target`)
                    }}
                    display="flex" flexDirection="column">
                    <ArgonButton style={{ fontSize: 10, padding: 5 }} color={"info"} size="small">
                      Preview PDF
                    </ArgonButton>
                  </ArgonBox>
                </ArgonBox>

                {/* <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search by product name..."
                    onChange={(e) => {
                      searchSalesFunction(e.target.value)
                    }}
                  />
                </ArgonBox> */}

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                  {/* <Grid container spacing={3} mb={3}>
                    <Grid item xs={12} lg={12}>
                      <GradientLineChart
                        title="Sales Overview"
                        description={
                          <ArgonBox display="flex" alignItems="center">
                            <ArgonBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                            </ArgonBox>
                            <ArgonTypography variant="button" color="text" fontWeight="medium">
                              <ArgonTypography variant="button" color="text" fontWeight="regular">
                                in {curentMonthYear}
                              </ArgonTypography>
                            </ArgonTypography>
                          </ArgonBox>
                        }
                        chart={graphData}
                      />
                    </Grid>
                  </Grid> */}
                  <ArgonBox p={2}>
                    {apiDatas.totalSumary.map((item, key) =>
                      <ArgonTypography key={key} fontWeight="medium">{item.name}: {Intl.NumberFormat().format(item.total_quantity)} PCS</ArgonTypography>
                    )}

                    <ArgonTypography fontWeight="medium">Total Sold Pieces: {Intl.NumberFormat().format(completeRowsData.total_quantity)}</ArgonTypography>
                    <ArgonTypography fontWeight="medium">Total Amount: TZS {Intl.NumberFormat().format(completeRowsData.total_amount)}/=</ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
                <ArgonBox display="flex" p={3}>
                  <ArgonButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        if (page <= 1) {
                          setPage(1)
                          setEndLoading(false)
                        } else {
                          setPage(page - 1)
                        }
                      }, 1000);
                    }}
                  >
                    &laquo; prev
                  </ArgonButton>

                  <ArgonButton
                    style={{ marginLeft: 10 }}
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        setPage(page + 1)
                      }, 1000);
                    }}
                  >
                    next &raquo;
                  </ArgonButton>
                </ArgonBox>

                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
              </Card>
            </ArgonBox>
          </ArgonBox>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default MonthlySales;
