import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
// Argon Dashboard 2 MUI base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components

// Data
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingDollar, faHandHoldingDroplet, faHistory, faMoneyBill } from "@fortawesome/free-solid-svg-icons";

function BusinessTransaction() {

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const { size } = typography;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <Card style={{ padding: 10, boxShadow: `1px 4px 2px 4px lightgrey`, marginBottom: 20 }}>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} md={6} lg={4}>
              <Link to={'/requested-funds'}>
                <DetailedStatisticsCard
                  title="Requested Funds"
                  icon={{ color: "success", component: <FontAwesomeIcon icon={faHandHoldingDollar} /> }}
                  percentage={{ color: "error", count: "", text: "" }}
                />
              </Link>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Link to={'/requested-funds-history'}>
                <DetailedStatisticsCard
                  title="Requested Funds History"
                  icon={{ color: "success", component: <FontAwesomeIcon icon={faHistory} /> }}
                  percentage={{ color: "error", count: "", text: "" }}
                />
              </Link>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Link to={'/expenses'}>
                <DetailedStatisticsCard
                  title="Expenses"
                  icon={{ color: "success", component: <FontAwesomeIcon icon={faMoneyBill} /> }}
                  percentage={{ color: "error", count: "", text: "" }}
                />
              </Link>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Link to={'/requested-processing-materials'}>
                <DetailedStatisticsCard
                  title="Requested Materials"
                  icon={{ color: "success", component: <FontAwesomeIcon icon={faHandHoldingDroplet} /> }}
                  percentage={{ color: "error", count: "", text: "" }}
                />
              </Link>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Link to={'/requested-processing-materials-history'}>
                <DetailedStatisticsCard
                  title="Requested Materials History"
                  icon={{ color: "success", component: <FontAwesomeIcon icon={faMoneyBill} /> }}
                  percentage={{ color: "error", count: "", text: "" }}
                />
              </Link>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Link to={'/materials-purchases'}>
                <DetailedStatisticsCard
                  title="Materials Purchases"
                  icon={{ color: "success", component: <FontAwesomeIcon icon={faMoneyBill} /> }}
                  percentage={{ color: "error", count: "", text: "" }}
                />
              </Link>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Link to={'/materials-usage-history'}>
                <DetailedStatisticsCard
                  title="Materials Usage History"
                  icon={{ color: "success", component: <FontAwesomeIcon icon={faHistory} /> }}
                  percentage={{ color: "error", count: "", text: "" }}
                />
              </Link>
            </Grid>

          </Grid>
        </Card>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BusinessTransaction;
