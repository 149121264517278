import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import ArgonAvatar from "components/ArgonAvatar";
import { useNavigate } from "react-router-dom";
import { SelectStyle } from "constants/SelectStyle";

function GrossReport() {
  const [apiDatas, setApiDatas] = useState({ rows: [], total_amount: 0, sum_total: 0, total_quantity: 0, totalSumary: [] })
  const [apiLoading, setApiLoading] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [startingDate, setStartingDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [users, setUsers] = useState([])
  const [salesPerson, setSalesPerson] = useState("")

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const getUsers = () => {
    const firstData = localStorage.getItem("Users_data_to_filter_sales_async")
    if (firstData) {
      setUsers(JSON.parse(firstData))
    }

    const url = `${API.url}get-all-sales-person?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Users_data_to_filter_sales_async", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Users_data_to_filter_sales_async")
        if (finalData) {
          setUsers(JSON.parse(finalData))
        }
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const completeRowsData = apiDatas

  const UsersData = {
    columns: [
      { name: "product", align: "left" },
      { name: "unit", align: "left" },
      { name: "selling_price", align: "left" },
      { name: "payment_method", align: "left" },
      { name: "quantity", align: "left" },
      { name: "total", align: "left" },
      { name: "customer_name", align: "left" },
      { name: "seller_name", align: "left" },
      { name: "created_at", align: "left" },
      { name: "proof_of_payment", align: "left" },
      { name: "action", align: "left" },
    ],

    rows: completeRowsData.rows.map((item) => {
      const data = {
        product: <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
          <ArgonBox mr={2}>
            <ArgonAvatar src={item.photo} alt={item.product_name} size="sm" variant="rounded" />
          </ArgonBox>
          <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography variant="button" fontWeight="medium">
              {item.product_name}
            </ArgonTypography>
            <ArgonTypography variant="caption" color="secondary">
              #Order ID: {item.order_id}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>,
        unit: item.unit,
        selling_price: Intl.NumberFormat().format(+item.selling_price),
        payment_method: item.payment_method,
        quantity: Intl.NumberFormat().format(+item.quantity),
        total: Intl.NumberFormat().format(+item.selling_price * +item.quantity),
        pay_date: item.created_at,
        customer_name: item.buyer,
        seller_name: item.seller_name,
        created_at: item.created_at,
        proof_of_payment: (
          <a href={item.pay_receipt} target="_blank"><img src={item.pay_receipt} width={100} height={80} style={{ border: "solid 1px lightgrey", borderRadius: 4 }} /></a>
        ),
        action: (
          <>
            {localStorage.getItem("role") === "ADMIN" && (
              <ArgonButton
                style={{ marginLeft: 5 }}
                size="small"
                color="warning"
                onClick={() => {
                  swal({
                    title: "Hold on!",
                    text: "Are you sure you want to abort sales ?, You will not be able to recover this data!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  })
                    .then((willDelete) => {
                      if (willDelete) {
                        setApiLoading(true)
                        const url = `${API.url}delete-sales-order/${encode(item.order_id)}?key=${API.key}`
                        fetch(url)
                          .then(res => res.json())
                          .then(res => {
                            setApiLoading(false)
                            getApiData()
                            swal(res.result, res.message, "success");
                          })
                          .catch(err => {
                            setApiLoading(false)
                            swal("Opps!", err.message, "warning");
                          })
                      } else {
                        swal("Action cancelled!");
                      }
                    });
                }}
              >
                Abort Sales
              </ArgonButton>
            )}
          </>
        )
      }
      return data
    }),
  };

  useEffect(() => {
    getUsers()
  }, []);

  const { columns, rows } = UsersData;

  const getApiData = (currentPage) => {
    if (startingDate === "" || endDate === "") {
      swal("Opps!", "Please select starting and end date", "warning");
    } else {
      setApiLoading(true)
      if (salesPerson !== "") {
        const url = `${API.url}get-date-range-sales/${encode(startingDate)}/${encode(endDate)}?page=${currentPage}&key=${API.key}&seller_id=${encode(salesPerson)}`
        fetch(url)
          .then(res => res.json())
          .then(data => {
            const finalData = data.data
            setApiDatas(finalData)

            setApiLoading(false)
            setEndLoading(false)
          })
          .catch(err => {
            setApiLoading(false)
            swal("Opps!", err.message, "warning");
          })

      } else {
        const url = `${API.url}get-date-range-sales/${encode(startingDate)}/${encode(endDate)}?page=${currentPage}&key=${API.key}`
        fetch(url)
          .then(res => res.json())
          .then(data => {
            const finalData = data.data
            setApiDatas(finalData)

            setApiLoading(false)
            setEndLoading(false)
          })
          .catch(err => {
            setApiLoading(false)
            swal("Opps!", err.message, "warning");
          })
      }
    }
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">Gross Report</ArgonTypography>
                  {apiDatas.rows.length > 0 && (
                    <ArgonBox
                      onClick={() => {
                        if (salesPerson !== "") {
                          window.open(`${API.url}get-date-range-sales-pdf-report/${encode(startingDate)}/${encode(endDate)}?key=${API.key}&seller_id=${encode(salesPerson)}`, `_target`)
                        } else {
                          window.open(`${API.url}get-date-range-sales-pdf-report/${encode(startingDate)}/${encode(endDate)}?key=${API.key}`, `_target`)
                        }
                      }}
                      display="flex" flexDirection="column">
                      <ArgonButton style={{ fontSize: 10, padding: 5 }} color={"info"} size="small">
                        Preview PDF
                      </ArgonButton>
                    </ArgonBox>
                  )}
                </ArgonBox>

                <ArgonBox display="flex" p={3}>
                  <ArgonBox p={1} style={{ width: "33%" }}>
                    <ArgonTypography variant="button" color="text" fontWeight="medium">Starting Date</ArgonTypography>
                    <ArgonInput
                      placeholder="Start Date"
                      value={startingDate}
                      onChange={(e) => {
                        setStartingDate(e.target.value)
                      }}
                      type="date"
                    />
                  </ArgonBox>

                  <ArgonBox p={1} style={{ width: "33%" }}>
                    <ArgonTypography variant="button" color="text" fontWeight="medium">End Date</ArgonTypography>
                    <ArgonInput
                      placeholder="End Date"
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e.target.value)
                      }}
                      type="date"
                    />
                  </ArgonBox>
                  <ArgonBox p={1} style={{ width: "33%" }}>
                    <ArgonTypography variant="button" color="text" fontWeight="medium">Sales Person</ArgonTypography>
                    <select value={salesPerson} onChange={(e) => setSalesPerson(e.target.value)} style={SelectStyle} autoFocus>
                      <option value={""}>--All--</option>
                      {users.map((item, idx) =>
                        <option key={idx} value={item.id}>{item.first_name} {item.middle_name} {item.last_name}</option>
                      )}
                    </select>
                  </ArgonBox>

                  <ArgonBox p={1} style={{ width: "10%" }}>
                    <ArgonTypography variant="button" color="text" fontWeight="medium">-------</ArgonTypography>

                    <ArgonButton onClick={() => { getApiData(1) }} color="primary">Filter</ArgonButton>
                  </ArgonBox>
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                  <ArgonBox p={2}>
                    {apiDatas.totalSumary.map((item, key) =>
                      <ArgonTypography key={key} fontWeight="medium">{item.name}: {Intl.NumberFormat().format(item.total_quantity)} PCS</ArgonTypography>
                    )}

                    <ArgonTypography fontWeight="medium">Total Sold Pieces: {Intl.NumberFormat().format(completeRowsData.total_quantity)}</ArgonTypography>
                    <ArgonTypography fontWeight="medium">Total Amount: TZS {Intl.NumberFormat().format(completeRowsData.total_amount)}/=</ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
                {apiDatas.rows.length > 0 && (
                  <ArgonBox display="flex" p={3}>
                    <ArgonButton
                      color="primary"
                      size="small"
                      onClick={() => {
                        setEndLoading(true)
                        setTimeout(() => {
                          if (page <= 1) {
                            setPage(1)
                            getApiData(1)
                            setEndLoading(false)
                          } else {
                            setPage(page - 1)
                            getApiData(page - 1)
                          }
                        }, 1000);
                      }}
                    >
                      &laquo; prev
                    </ArgonButton>

                    <ArgonButton
                      style={{ marginLeft: 10 }}
                      color="primary"
                      size="small"
                      onClick={() => {
                        setEndLoading(true)
                        setTimeout(() => {
                          setPage(page + 1)
                          getApiData(page + 1)
                        }, 1000);
                      }}
                    >
                      next &raquo;
                    </ArgonButton>
                  </ArgonBox>
                )}

                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
              </Card>
            </ArgonBox>
          </ArgonBox>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default GrossReport;
