import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import Modal from 'react-modal';

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import ArgonAvatar from "components/ArgonAvatar";
import { modalSettings } from "constants/ModalSettins";
import { FormGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";

function RequestLeaveHistory() {
  const [apiWards, setApiWards] = useState([])
  const [apiWardsSearch, setApiWardsSearch] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [selectedUpdate, setSelectUpdate] = useState("")
  const [userId, setUserId] = useState("")
  const [updateModal, setUpdateModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [details, setDetails] = useState("")

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const completeRowsData = apiWardsSearch && apiWardsSearch.length > 0 ? apiWardsSearch : apiWards

  const UsersData = {
    columns: [
      { name: "full_name", align: "left" },
      { name: "request_details", align: "left" },
      { name: "approval_details", align: "left" },
      { name: "status", align: "left" },
      { name: "checked_by", align: "left" },
      { name: "created_at", align: "left" },
    ],

    rows: completeRowsData.map((item) => {
      const data = {
        full_name: <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
          <ArgonBox mr={2}>
            <ArgonAvatar src={item.photo} alt={`${item.first_name} ${item.middle_name} ${item.last_name}`} size="sm" variant="rounded" />
          </ArgonBox>
          <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography variant="button" fontWeight="medium">
              {`${item.first_name} ${item.middle_name} ${item.last_name}`}
            </ArgonTypography>
            <ArgonTypography variant="caption" color="secondary">
              {item.email}
              <br />
              {item.phone_number}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>,
        request_details: item.details,
        approval_details: item.approve_details,
        status: <ArgonBox display="flex" flexDirection="column">
          <ArgonButton style={{ fontSize: 10, padding: 5 }} color={item.status === "APPROVED" ? "secondary" : "warning"} size="small">
            {item.status}
          </ArgonButton>
        </ArgonBox>,
        checked_by: item.approved_by,
        created_at: item.created_at,
      }
      return data
    }),
  };

  const { columns, rows } = UsersData;

  const rejectRequestLeave = (user_id, request_id) => {
    swal({
      title: "Hold on!",
      text: "Are you sure you want to proceed?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          rejectRequestLeaveComplete(user_id, request_id)
        } else {
          swal("Action cancelled!");
        }
      });
  }

  const rejectRequestLeaveComplete = (user_id, request_id) => {
    setApiLoading(true)
    const formData = new FormData()
    formData.append("message", "Samahani ombi lako la likizo limekataliwa...\n Tafadhali jaribu tena wakati mwingine")
    formData.append("user_id", encode(user_id))
    formData.append("request_id", encode(request_id))
    formData.append("status", encode("REJECTED"))
    formData.append("title", encode("OMBI LIMEKATALIWA"))
    formData.append("admin_id", encode(localStorage.getItem("user_id")))
    fetch(`${API.url}change-leave-request-status?key=${API.key}`, { method: "POST", body: formData })
      .then(res => res.json())
      .then(res => {
        getApiData()
        setApiLoading(false)
        swal(res.result, res.message, res.icon);
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const approveRequestFunction = () => {
    if (details === "") {
      swal("Opps!", "Please fill all required information", "warning");
    } else {
      setIsLoading(true)
      const form = new FormData()
      form.append("message", details)
      form.append("user_id", encode(userId))
      form.append("request_id", encode(selectedUpdate))
      form.append("status", encode("APPROVED"))
      form.append("title", encode("OMBI LIMETHIBITISHWA"))
      form.append("admin_id", encode(localStorage.getItem("user_id")))
      fetch(`${API.url}change-leave-request-status?key=${API.key}`, { method: "POST", body: form })
        .then(res => res.json())
        .then(res => {
          getApiData()
          clearHooks()
          setUpdateModal(false)
          setIsLoading(false)
          swal(res.result, res.message, res.icon);
        })
        .catch(err => {
          setIsLoading(false)
          swal("Opps!", err.message, "warning");
        })
    }
  }

  const getApiData = () => {
    const startData = localStorage.getItem("Request_leave_History_data_async")
    if (startData) {
      setApiWards(JSON.parse(startData))
      setApiLoading(false)
      setEndLoading(false)
    }

    const url = `${API.url}get-all-request-leave/${encode("APPROVED")}?page=${page}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Request_leave_History_data_async", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Request_leave_History_data_async")
        setApiWards(JSON.parse(finalData))

        setApiLoading(false)
        setEndLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, [page]);

  const clearHooks = () => {
    setSelectUpdate("")
    setDetails("")
    setIsLoading(false)
  }

  const searchUsersFunction = (search) => {
    const url = `${API.url}get-all-request-leave-by-search/${encode("APPROVED")}?search=${encode(search)}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiWardsSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">Request Leave</ArgonTypography>
                </ArgonBox>

                <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search Here..."
                    onChange={(e) => {
                      searchUsersFunction(e.target.value)
                    }}
                  />
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </ArgonBox>
                <ArgonBox display="flex" p={3}>
                  <ArgonButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        if (page <= 1) {
                          setPage(1)
                          setEndLoading(false)
                        } else {
                          setPage(page - 1)
                        }
                      }, 1000);
                    }}
                  >
                    &laquo; prev
                  </ArgonButton>

                  <ArgonButton
                    style={{ marginLeft: 10 }}
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        setPage(page + 1)
                      }, 1000);
                    }}
                  >
                    next &raquo;
                  </ArgonButton>
                </ArgonBox>
                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
              </Card>
            </ArgonBox>
          </ArgonBox>

          <Modal
            isOpen={updateModal}
            style={modalSettings}
            contentLabel=""
          >
            <div style={{ float: "right" }}>
              <ArgonButton
                color="warning"
                onClick={() => {
                  clearHooks()
                  setUpdateModal(false)
                }}
              >
                close
              </ArgonButton>
            </div>
            <br />
            <h2>Approve Request</h2>

            <FormGroup>
              <ArgonTypography variant="button" color="text" fontWeight="medium">Details</ArgonTypography>
              <textarea rows={10} onChange={(e) => setDetails(e.target.value)} value={details} placeholder="Enter Details" ></textarea>
            </FormGroup>

            <br />
            {isLoading === true ? (
              <Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />
            ) : (
              <ArgonButton onClick={() => approveRequestFunction()} color="primary">Approve</ArgonButton>
            )}
          </Modal>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default RequestLeaveHistory;
