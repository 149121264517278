import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import { useNavigate } from "react-router-dom";
import { SelectStyle } from "constants/SelectStyle";
import { encode } from 'base-64';

function TransferSalesProducts() {
  const [apiDatas, setApiDatas] = useState({ rows: [], total_amount: 0, sum_total: 0, total_quantity: 0, totalSumary: [] })
  const [apiLoading, setApiLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [fromUser, setFromUser] = useState("")
  const [toUser, setToUser] = useState("")
  const [quantity, setQuantity] = useState("")
  const [productId, setProductId] = useState("")
  const [userProduts, setUserProducts] = useState([])

  const clearHooks = () => {
    setUserProducts([])
    setProductId("")
    setQuantity("")
    setToUser("")
    setFromUser("")
  }

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const getUsers = () => {
    const firstData = localStorage.getItem("Users_data_to_filter_sales_async")
    if (firstData) {
      setUsers(JSON.parse(firstData))
    }

    const url = `${API.url}get-all-sales-person?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem("Users_data_to_filter_sales_async", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Users_data_to_filter_sales_async")
        if (finalData) {
          setUsers(JSON.parse(finalData))
        }
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }


  useEffect(() => {
    getUsers()
  }, []);

  const transferProductsFunction = () => {
    if (fromUser == toUser) {
      swal("Opps!", "You can not transfer products to the same user!", "warning");
    } else if (fromUser === "" || toUser === "" || quantity === "" || productId === "") {
      swal("Opps!", "Please fill all required information!", "warning");
    } else {
      setApiLoading(true)
      const formData = new FormData()
      formData.append("from_user", encode(fromUser))
      formData.append("to_user", encode(toUser))
      formData.append("product_id", encode(productId))
      formData.append("quantity", encode(quantity))
      fetch(`${API.url}transfer-product?key=${API.key}`, { method: "POST", body: formData })
        .then(res => res.json())
        .then(res => {
          setApiLoading(false)
          getUsers()
          clearHooks()
          swal(res.result, res.message, res.icon);
        })
        .catch(err => {
          setApiLoading(false)
          swal("Opps!", err.message, "warning");
        })
    }
  }

  const getSellerProducts = (value) => {
    setFromUser(value)
    if (value !== "") {
      setApiLoading(true)
      const url = `${API.url}get-seller-products/${encode(value)}?key=${API.key}`
      fetch(url)
        .then(res => res.json())
        .then(data => {
          setUserProducts(data.data)
          setApiLoading(false)
        })
        .catch(err => {
          setApiLoading(false)
          swal("Opps!", err.message, "warning");
        })
    }
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">Transfer Products</ArgonTypography>
                </ArgonBox>

                <ArgonBox p={3}>
                  <ArgonBox p={1}>
                    <ArgonTypography variant="button" color="text" fontWeight="medium">From *</ArgonTypography>
                    <select value={fromUser} onChange={(e) => getSellerProducts(e.target.value)} style={SelectStyle} >
                      <option value={""}>--Select User--</option>
                      {users.map((item, idx) =>
                        <option key={idx} value={item.id}>{item.first_name}  {item.last_name} ({item.region})</option>
                      )}
                    </select>
                  </ArgonBox>

                  {userProduts.length > 0 && (
                    <ArgonBox p={1}>
                      <ArgonTypography variant="button" color="text" fontWeight="medium">Product to Transfer *</ArgonTypography>
                      <select value={productId} onChange={(e) => setProductId(e.target.value)} style={SelectStyle} >
                        <option value={""}>--Select Product--</option>
                        {userProduts.map((item, idx) =>
                          <option key={idx} value={item.product_id}>{item.name}  {item.unit} (Available: {Intl.NumberFormat().format(item.quantity)} PCS)</option>
                        )}
                      </select>
                    </ArgonBox>
                  )}

                  <ArgonBox p={1}>
                    <ArgonTypography variant="button" color="text" fontWeight="medium">To *</ArgonTypography>
                    <select value={toUser} onChange={(e) => setToUser(e.target.value)} style={SelectStyle} >
                      <option value={""}>--Select User to Transfer--</option>
                      {users.map((item, idx) =>
                        <option key={idx} value={item.id}>{item.first_name}  {item.last_name} ({item.region})</option>
                      )}
                    </select>
                  </ArgonBox>

                  <ArgonBox p={1}>
                    <ArgonTypography variant="button" color="text" fontWeight="medium">Quantity to Transfer *</ArgonTypography>
                    <ArgonInput
                      placeholder="Quantity to Transfer"
                      value={quantity}
                      onChange={(e) => {
                        setQuantity(e.target.value)
                      }}
                      type="number"
                    />
                  </ArgonBox>

                  <ArgonBox p={1} style={{ width: "10%" }}>
                    {/* <ArgonTypography variant="button" color="text" fontWeight="medium">-------</ArgonTypography> */}

                    <ArgonButton onClick={() => { transferProductsFunction() }} color="primary">Transfer</ArgonButton>
                  </ArgonBox>
                </ArgonBox>
              </Card>
            </ArgonBox>
          </ArgonBox>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default TransferSalesProducts;
