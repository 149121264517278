import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
// Argon Dashboard 2 MUI base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components

// Data
import { useEffect, useState } from "react";
import API from "Api/Api";
import MainLoading from "Helpers/MainLoading";
import { useNavigate } from "react-router-dom";

function SalesReport() {
  const [dashboardData, setDashboardData] = useState(null)
  const [graphDataYearly, setGraphDataYearly] = useState({})
  const [graphDataMonthly, setGraphDataMonthly] = useState({})
  const [graphDataDaily, setGraphDataDaily] = useState({})

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const getApiData = () => {
    const getData = localStorage.getItem("Dashboard_data_async_")
    const startingData = JSON.parse(getData)
    if (startingData) {
      const data = {
        labels: startingData.yeary_sales.months,
        datasets: [
          {
            label: "Items",
            color: "info",
            data: startingData.yeary_sales.sales,
          },
        ],
      };
      setGraphDataYearly(data)

      const dataMonthly = {
        labels: startingData.monthly_report.labels,
        datasets: [
          {
            label: "Items",
            color: "info",
            data: startingData.monthly_report.datas,
          },
        ],
      };
      setGraphDataMonthly(dataMonthly)

      const dataDaily = {
        labels: startingData.daily_report.labels,
        datasets: [
          {
            label: "Items",
            color: "info",
            data: startingData.daily_report.datas,
          },
        ],
      };
      setGraphDataDaily(dataDaily)
      setDashboardData(startingData)
    } else {
      const data = {
        labels: [],
        datasets: [
          {
            label: "Items",
            color: "info",
            data: [],
          },
        ],
      };
      setGraphDataYearly(data)

      const dataMonthly = {
        labels: [],
        datasets: [
          {
            label: "Items",
            color: "info",
            data: [],
          },
        ],
      };
      setGraphDataMonthly(dataMonthly)

      const dataDaily = {
        labels: [],
        datasets: [
          {
            label: "Items",
            color: "info",
            data: [],
          },
        ],
      };
      setGraphDataDaily(dataDaily)
    }

    const url = `${API.url}get-dashboard-data?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(res => {
        localStorage.setItem("Dashboard_data_async_", JSON.stringify(res.data))
        const getData = localStorage.getItem("Dashboard_data_async_")
        const completeData = JSON.parse(getData)
        const data = {
          labels: completeData.yeary_sales.months,
          datasets: [
            {
              label: "Items",
              color: "info",
              data: completeData.yeary_sales.sales,
            },
          ],
        };
        setGraphDataYearly(data)

        const _dataMonthly = {
          labels: completeData.monthly_report.labels,
          datasets: [
            {
              label: "Items",
              color: "info",
              data: completeData.monthly_report.datas,
            },
          ],
        };
        setGraphDataMonthly(_dataMonthly)

        const _dataDaily = {
          labels: completeData.daily_report.labels,
          datasets: [
            {
              label: "Items",
              color: "info",
              data: completeData.daily_report.datas,
            },
          ],
        };
        setGraphDataDaily(_dataDaily)
        setDashboardData(completeData)
      })
      .catch(err => { })
  }

  useEffect(() => {
    getApiData()
  }, []);

  const { size } = typography;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <>{!dashboardData ? (<MainLoading />) : (
        <>
          <ArgonBox py={3}>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} lg={12}>
                <GradientLineChart
                  title="Daily Sales Overview"
                  description={
                    <ArgonBox display="flex" alignItems="center">
                      <ArgonTypography variant="button" color="text" fontWeight="medium">
                        <ArgonTypography variant="button" color="text" fontWeight="regular">
                        </ArgonTypography>
                      </ArgonTypography>
                    </ArgonBox>
                  }
                  chart={graphDataDaily}
                />
                <hr />
              </Grid>

              <Grid item xs={12} lg={12}>
                <GradientLineChart
                  title="Monthly Sales Overview"
                  description={
                    <ArgonBox display="flex" alignItems="center">
                      <ArgonTypography variant="button" color="text" fontWeight="medium">
                        <ArgonTypography variant="button" color="text" fontWeight="regular">
                          {/* in {new Date().getFullYear()} */}
                        </ArgonTypography>
                      </ArgonTypography>
                    </ArgonBox>
                  }
                  chart={graphDataMonthly}
                />
                <hr />
              </Grid>

              <Grid item xs={12} lg={12}>
                <GradientLineChart
                  title="Yearly Sales Overview"
                  description={
                    <ArgonBox display="flex" alignItems="center">
                      <ArgonTypography variant="button" color="text" fontWeight="medium">
                        <ArgonTypography variant="button" color="text" fontWeight="regular">
                          in {new Date().getFullYear()}
                        </ArgonTypography>
                      </ArgonTypography>
                    </ArgonBox>
                  }
                  chart={graphDataYearly}
                />
              </Grid>

            </Grid>
          </ArgonBox>
          <Footer />
        </>
      )}</>
    </DashboardLayout>
  );
}

export default SalesReport;
