import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import { useNavigate } from "react-router-dom";
import ArgonAvatar from "components/ArgonAvatar";
import { encode } from 'base-64';

function RegionalProductsRequestHistory() {
  const [apiRegions, setApiRegions] = useState([])
  const [apiRegionsSearch, setApiRegionsSearch] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const completeRowsData = apiRegionsSearch.length > 0 ? apiRegionsSearch : apiRegions

  const regionsData = {
    columns: [
      { name: "sn", align: "left" },
      { name: "product_name", align: "left" },
      { name: "requested_quantity", align: "left" },
      { name: "approved_quantity", align: "left" },
      { name: "requested_by", align: "left" },
      { name: "status", align: "left" },
      { name: "created_at", align: "center" },
      { name: "action", align: "center" },
    ],

    rows: completeRowsData.map((item, idx) => {
      const data = {
        sn: <p style={{ padding: 10 }}>{++idx}</p>,
        product_name: <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
          <ArgonBox mr={2}>
            <ArgonAvatar src={item.photo} alt={`${item.name}`} size="sm" variant="rounded" />
          </ArgonBox>
          <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography variant="button" fontWeight="medium">
              {`${item.name}`}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>,
        requested_quantity: Intl.NumberFormat().format(item.requested_quantity),
        approved_quantity: <p>{Intl.NumberFormat().format(item.approved_quantity)}</p>,
        requested_by: item.requested_by,
        status: <ArgonButton color={item.status === "APPROVED" ? "success" : "warning"} size="small">{item.status}</ArgonButton>,
        created_at: item.created_at,
        action: (
          <>
            {localStorage.getItem("role") === "ADMIN" && item.status == "REQUESTED" && (
              <ArgonButton
                style={{ marginLeft: 5 }}
                size="small"
                color="warning"
                onClick={() => {
                  swal({
                    title: "Hold on!",
                    text: "Are you sure you want to abort this request ?, You will not be able to recover this data!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  })
                    .then((willDelete) => {
                      if (willDelete) {
                        setApiLoading(true)
                        const url = `${API.url}delete-regional-request-product/${encode(item.id)}?key=${API.key}`
                        fetch(url)
                          .then(res => res.json())
                          .then(res => {
                            setApiLoading(false)
                            getApiData()
                            swal(res.result, res.message, "success");
                          })
                          .catch(err => {
                            setApiLoading(false)
                            swal("Opps!", err.message, "warning");
                          })
                      } else {
                        swal("Action cancelled!");
                      }
                    });
                }}
              >
                Abort Request
              </ArgonButton>
            )}
          </>
        )
      }
      return data
    }),
  };

  const { columns, rows } = regionsData;

  const getApiData = () => {
    const startData = localStorage.getItem("regional_products_request_history_data_async")
    if (startData) {
      setApiRegions(JSON.parse(startData))
      setApiLoading(false)
    }

    const url = `${API.url}get-regional-products-request-history?page=${page}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setEndLoading(false)
        setApiLoading(false)
        localStorage.setItem("regional_products_request_history_data_async", JSON.stringify(data.data))
        const finalData = localStorage.getItem("regional_products_request_history_data_async")
        setApiRegions(JSON.parse(finalData))
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, []);

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, [page]);

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">Regional Products Request History</ArgonTypography>
                </ArgonBox>

                {/* <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search Here..."
                    onChange={(e) => {
                      searchRegionFunction(e.target.value)
                    }}
                  />
                </ArgonBox> */}

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </ArgonBox>
                <ArgonBox display="flex" p={3}>
                  <ArgonButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        if (page <= 1) {
                          setPage(1)
                          setEndLoading(false)
                        } else {
                          setPage(page - 1)
                        }
                      }, 1000);
                    }}
                  >
                    &laquo; prev
                  </ArgonButton>

                  <ArgonButton
                    style={{ marginLeft: 10 }}
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEndLoading(true)
                      setTimeout(() => {
                        setPage(page + 1)
                      }, 1000);
                    }}
                  >
                    next &raquo;
                  </ArgonButton>
                </ArgonBox>
                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
              </Card>
            </ArgonBox>
          </ArgonBox>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default RegionalProductsRequestHistory;
