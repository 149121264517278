import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
// Argon Dashboard 2 MUI base styles

// Dashboard layout components

// Data
import { Link, useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

function LocationDashboard() {

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <>
        <ArgonBox py={3}>
          <Card style={{ padding: 10, boxShadow: `1px 4px 2px 4px lightgrey`, marginBottom: 20 }}>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} md={6} lg={4}>
                <Link to={'/regions'}>
                  <DetailedStatisticsCard
                    title="Regions"
                    icon={{ color: "success", component: <FontAwesomeIcon icon={faMapMarkerAlt} /> }}
                    percentage={{ color: "error", count: "", text: "" }}
                  />
                </Link>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Link to={'/districts'}>
                  <DetailedStatisticsCard
                    title="Districts"
                    icon={{ color: "success", component: <FontAwesomeIcon icon={faMapMarkerAlt} /> }}
                    percentage={{ color: "error", count: "", text: "" }}
                  />
                </Link>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Link to={'/wards'}>
                  <DetailedStatisticsCard
                    title="Wards"
                    icon={{ color: "success", component: <FontAwesomeIcon icon={faMapMarkerAlt} /> }}
                    percentage={{ color: "error", count: "", text: "" }}
                  />
                </Link>
              </Grid>
            </Grid>
          </Card>
        </ArgonBox>
        <Footer />
      </>
    </DashboardLayout>
  );
}

export default LocationDashboard;
