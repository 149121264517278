import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import { modalSettings } from "constants/ModalSettins";
import { FormGroup } from "@mui/material";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import { useNavigate } from "react-router-dom";

function RequestedMaterials() {
  const [name, setName] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [apiRegions, setApiRegions] = useState([])
  const [apiRegionsSearch, setApiRegionsSearch] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [selectedUpdate, setSelectUpdate] = useState("")
  const [updateModal, setUpdateModal] = useState(false)
  const [sellingPrice, setSellingPrice] = useState("")
  const [productPhoto, setProductPhoto] = useState("")
  const [unit, setUnit] = useState("")

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const completeRowsData = apiRegionsSearch.length > 0 ? apiRegionsSearch : apiRegions

  const regionsData = {
    columns: [
      { name: "sn", align: "left" },
      { name: "name", align: "left" },
      { name: "unit", align: "left" },
      { name: "requested_quantity", align: "left" },
      { name: "buying_price", align: "left" },
      { name: "requested_by", align: "left" },
      { name: "created_at", align: "center" },
      { name: "action", align: "center" },
    ],

    rows: completeRowsData.map((item, idx) => {
      const data = {
        sn: <p style={{ padding: 10 }}>{++idx}</p>,
        name: <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
          <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography variant="button" fontWeight="medium">
              {`${item.name}`}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>,
        unit: item.unit,
        requested_quantity: Intl.NumberFormat().format(item.quantity),
        buying_price: <p>TZS {Intl.NumberFormat().format(item.buying_price)}</p>,
        requested_by: item.requested_by,
        created_at: item.created_at,

        action: (
          <>
            <ArgonButton
              color="info"
              size="small"
              onClick={() => {
                setSelectUpdate(item.id)
                setName(item.name)
                setSellingPrice(item.buying_price)
                setProductPhoto(item.photo)
                setUnit(item.quantity)
                setUpdateModal(true)
              }}
            >
              Approve
            </ArgonButton>
          </>
        ),
      }
      return data
    }),
  };

  const { columns, rows } = regionsData;

  const getApiData = () => {
    const startData = localStorage.getItem("requested_processing_materials_data_async")
    if (startData) {
      setApiRegions(JSON.parse(startData))
      setApiLoading(false)
    }

    const url = `${API.url}get-requested-processing-materials?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiLoading(false)
        localStorage.setItem("requested_processing_materials_data_async", JSON.stringify(data.data))
        const finalData = localStorage.getItem("requested_processing_materials_data_async")
        setApiRegions(JSON.parse(finalData))
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, []);


  const clearHooks = () => {
    setName("")
    setSellingPrice("")
    setSelectUpdate("")
    setUnit("")
  }

  const updateRegionFunction = () => {
    if (name === "") {
      swal("Opps!", "Please fill all required informations", "warning");
    } else {
      setIsLoading(true)
      setTimeout(() => {
        const formData = new FormData()
        formData.append("request_id", encode(selectedUpdate))
        formData.append("quantity", encode(unit))
        formData.append("buying_price", encode(sellingPrice))
        fetch(`${API.url}approve-requested-material?key=${API.key}`, { method: "POST", body: formData })
          .then(res => res.json())
          .then(res => {
            getApiData()
            setUpdateModal(false)
            clearHooks()
            setIsLoading(false)
            if (res.result === "SUCCESS") {
              setName("")
              swal(res.result, res.message, "success")
            } else {
              swal(res.result, res.message, "warning")
            }
          })
          .catch(err => {
            setIsLoading(false)
            swal("Opps!", err.message, "warning");
          })
      }, 1000);
    }
  }

  const searchRegionFunction = (search) => {
    const url = `${API.url}get-requested-processing-materials-by-search?search=${encode(search)}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiRegionsSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">Requested Processing Materials Table</ArgonTypography>
                </ArgonBox>

                <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search Here..."
                    onChange={(e) => {
                      searchRegionFunction(e.target.value)
                    }}
                  />
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </ArgonBox>
              </Card>
            </ArgonBox>
          </ArgonBox>

          {/* UPDATE MODAL */}
          <Modal
            isOpen={updateModal}
            style={modalSettings}
            contentLabel="Register Processing Material"
          >
            <div style={{ float: "right" }}>
              <ArgonButton
                color="warning"
                onClick={() => {
                  clearHooks()
                  setUpdateModal(false)
                }}
              >
                close
              </ArgonButton>
            </div>
            <br />
            <br />
            <h2>Approve Request</h2>

            <FormGroup>
              <ArgonTypography variant="button" color="text" fontWeight="medium">Quantity</ArgonTypography>
              <ArgonInput onChange={(e) => setUnit(e.target.value)} value={unit} placeholder="Enter Quantity" />
            </FormGroup>
            <br />
            <FormGroup>
              <ArgonTypography variant="button" color="text" fontWeight="medium">Buying Price</ArgonTypography>
              <ArgonInput type="number" onChange={(e) => setSellingPrice(e.target.value)} value={sellingPrice} readOnly placeholder="Enter Processing Material Buying Price" />
            </FormGroup>
            <br />
            {isLoading === true ? (
              <Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />
            ) : (
              <ArgonButton onClick={() => updateRegionFunction()} color="primary">Approve</ArgonButton>
            )}
          </Modal>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default RequestedMaterials;
