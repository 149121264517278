import { userRolesJson } from "../constants/StaticDatas"

export default function GetUserRole({ role }) {
  if (role === "SELLER") {
    return userRolesJson.SELLER
  } else if (role === "REGIONAL_STORE") {
    return userRolesJson.REGIONAL_STORE
  } else if (role === "RURAL_PROCUREMENT") {
    return userRolesJson.RURAL_PROCUREMENT
  } else if (role === "ACCOUNTANT") {
    return userRolesJson.ACCOUNTANT
  } else if (role === "MANAGER") {
    return userRolesJson.MANAGER
  } else if (role === "HEAD_OF_SALES") {
    return userRolesJson.HEAD_OF_SALES
  } else if (role === "ADMIN") {
    return userRolesJson.ADMIN
  } else if (role === "MAIN_STORE") {
    return userRolesJson.MAIN_STORE
  }
}