import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
// Argon Dashboard 2 MUI base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components

// Data
import { useEffect, useState } from "react";
import API from "Api/Api";
import MainLoading from "Helpers/MainLoading";
import { todayDate } from "constants/StaticDatas";
import { currentMonth } from "constants/StaticDatas";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCalendarDay, faHistory, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

function ClientsDashboard() {
  const [dashboardData, setDashboardData] = useState(null)
  const [graphData, setGraphData] = useState(null)

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const getApiData = () => {
    const getData = localStorage.getItem("ClientsDashboad_data_async__")
    const startData = JSON.parse(getData)
    if (startData) {
      const data = {
        labels: startData.graph.labels,
        datasets: [
          {
            label: "Items",
            color: "info",
            data: startData.graph.datas,
          },
        ],
      };
      setGraphData(data)
      setDashboardData(startData)
    } else {
      const data = {
        labels: [],
        datasets: [
          {
            label: "Items",
            color: "info",
            data: [],
          },
        ],
      };
      setGraphData(data)
    }
    setDashboardData(startData)

    const url = `${API.url}get-clients-dashboard?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(res => {
        localStorage.setItem("ClientsDashboad_data_async__", JSON.stringify(res.data))
        const getData = localStorage.getItem("ClientsDashboad_data_async__")
        const completeData = JSON.parse(getData)
        const compData = {
          labels: completeData.graph.labels,
          datasets: [
            {
              label: "Items",
              color: "info",
              data: completeData.graph.datas,
            },
          ],
        };
        setGraphData(compData)
        setDashboardData(completeData)
      })
      .catch(err => { })
  }

  useEffect(() => {
    getApiData()
  }, []);

  const { size } = typography;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <>{!dashboardData ? (<MainLoading />) : (
        <>
          <ArgonBox py={3}>
            <Card style={{ padding: 10, boxShadow: `1px 4px 2px 4px lightgrey`, marginBottom: 20 }}>
              <Grid container spacing={3} mb={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailedStatisticsCard
                    title="Today's Registered Clients"
                    count={``}
                    icon={{ color: "info", component: <FontAwesomeIcon icon={faCalendarDay} /> }}
                    percentage={{
                      color: "success", count: "", text: (
                        <ArgonBox>
                          <ArgonBox display="flex" flexDirection="column">
                            <ArgonTypography variant="button" color="text" fontWeight="medium">Shops: {Intl.NumberFormat().format(dashboardData.daily.shop_daily_clients)}</ArgonTypography>
                            <ArgonTypography variant="button" color="text" fontWeight="medium">Min Market: {Intl.NumberFormat().format(dashboardData.daily.min_daily_clients)}</ArgonTypography>
                            <ArgonTypography variant="button" color="text" fontWeight="medium">Super Market: {Intl.NumberFormat().format(dashboardData.daily.super_daily_clients)}</ArgonTypography>
                          </ArgonBox>
                          <hr />
                          <ArgonTypography variant="button" color="text" fontWeight="medium">Date: {todayDate}</ArgonTypography>
                        </ArgonBox>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailedStatisticsCard
                    title="Monthly's Registered Clients"
                    count={``}
                    icon={{ color: "error", component: <FontAwesomeIcon icon={faCalendarAlt} /> }}
                    percentage={{
                      color: "success", count: "", text: (
                        <ArgonBox>
                          <ArgonBox display="flex" flexDirection="column">
                            <ArgonTypography variant="button" color="text" fontWeight="medium">Shops: {Intl.NumberFormat().format(dashboardData.monthly.shop_monthly_clients)}</ArgonTypography>
                            <ArgonTypography variant="button" color="text" fontWeight="medium">Min Market: {Intl.NumberFormat().format(dashboardData.monthly.min_monthly_clients)}</ArgonTypography>
                            <ArgonTypography variant="button" color="text" fontWeight="medium">Super Market: {Intl.NumberFormat().format(dashboardData.monthly.super_monthly_clients)}</ArgonTypography>
                          </ArgonBox>
                          <hr />
                          <ArgonTypography variant="button" color="text" fontWeight="medium">{currentMonth}</ArgonTypography>
                        </ArgonBox>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailedStatisticsCard
                    title="Total Clients"
                    count={``}
                    icon={{ color: "error", component: <FontAwesomeIcon icon={faUsers} /> }}
                    percentage={{
                      color: "success", count: "", text: (
                        <ArgonBox>
                          <ArgonBox display="flex" flexDirection="column">
                            <ArgonTypography variant="button" color="text" fontWeight="medium">Shops: {Intl.NumberFormat().format(dashboardData.total.shop_clients)}</ArgonTypography>
                            <ArgonTypography variant="button" color="text" fontWeight="medium">Min Market: {Intl.NumberFormat().format(dashboardData.total.min_clients)}</ArgonTypography>
                            <ArgonTypography variant="button" color="text" fontWeight="medium">Super Market: {Intl.NumberFormat().format(dashboardData.total.super_clients)}</ArgonTypography>
                          </ArgonBox>
                          <hr />
                          <ArgonTypography variant="button" color="text" fontWeight="medium">Total Clients</ArgonTypography>
                        </ArgonBox>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Card>

            <Card style={{ padding: 10, boxShadow: `1px 4px 2px 4px lightgrey`, marginBottom: 20 }}>
              <ArgonBox display="flex">
                <ArgonBox style={{ width: "50%" }}>
                  <Grid container spacing={1} mb={3}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Link to={'/clients'}>
                        <DetailedStatisticsCard
                          title="Clients Accounts"
                          icon={{ color: "success", component: <FontAwesomeIcon icon={faUsers} /> }}
                          percentage={{ color: "error", text: Intl.NumberFormat().format(dashboardData.all_clients), count: "Total Clients" }}
                        />
                      </Link>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Link to={'/clients-transaction'}>
                        <DetailedStatisticsCard
                          title="Clients Transaction History"
                          icon={{ color: "success", component: <FontAwesomeIcon icon={faHistory} /> }}
                          percentage={{ color: "error", count: "", text: "" }}
                        />
                      </Link>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Link to={'/clients-orders'}>
                        <DetailedStatisticsCard
                          title="Clients Orders"
                          icon={{ color: "success", component: <FontAwesomeIcon icon={faShoppingCart} /> }}
                          percentage={{ color: "error", text: Intl.NumberFormat().format(dashboardData.new_clients_orders), count: "Pending Orders" }}
                        />
                      </Link>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Link to={'/processed-clients-orders'}>
                        <DetailedStatisticsCard
                          title="Processed Clients Orders"
                          icon={{ color: "success", component: <FontAwesomeIcon icon={faCheckCircle} /> }}
                          percentage={{ color: "error", text: Intl.NumberFormat().format(dashboardData.sold_clients_orders), count: "Proccessed Orders" }}
                        />
                      </Link>
                    </Grid>

                  </Grid>
                </ArgonBox>

                <ArgonBox style={{ width: "50%" }}>
                  {graphData && (
                    <GradientLineChart
                      title="Clients Overview"
                      description={
                        <ArgonBox display="flex" alignItems="center">
                          <ArgonBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                          </ArgonBox>

                        </ArgonBox>
                      }
                      chart={graphData}
                    />
                  )}
                </ArgonBox>
              </ArgonBox>
            </Card>
          </ArgonBox>
          <Footer />
        </>
      )}</>
    </DashboardLayout>
  );
}

export default ClientsDashboard;
