import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
// Argon Dashboard 2 MUI base styles

// Dashboard layout components

// Data
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiagramSuccessor, faHandHoldingDollar, faLineChart, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import API from "Api/Api";
import MainLoading from "Helpers/MainLoading";

function AccountsDashboard() {
  const [dashboardData, setDashboardData] = useState(null)

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const getApiData = () => {
    const getData = localStorage.getItem("Dashboard_data_async_New")
    const completeData = JSON.parse(getData)
    if (completeData) {
      setDashboardData(completeData)
    }
    const url = `${API.url}get-dashboard-data?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(res => {
        localStorage.setItem("Dashboard_data_async_New", JSON.stringify(res.data))
        const getData = localStorage.getItem("Dashboard_data_async_New")
        const completeData = JSON.parse(getData)
        setDashboardData(completeData)
      })
      .catch(err => { })
  }

  useEffect(() => {
    getApiData()
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!dashboardData ? (<MainLoading />) : (
        <>
          <ArgonBox py={3}>
            <Card style={{ padding: 10, boxShadow: `1px 4px 2px 4px lightgrey`, marginBottom: 20 }}>
              <Grid container spacing={3} mb={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/sales-on-credit'}>
                    <DetailedStatisticsCard
                      title="Accounts Receivable"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faHandHoldingDollar} /> }}
                      percentage={{ color: "error", count: "Total: TZS", text: Intl.NumberFormat().format(dashboardData.account.account_receivable) }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/sales-on-credit'}>
                    <DetailedStatisticsCard
                      title="Idadi ya Wadaiwa"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faHandHoldingDollar} /> }}
                      percentage={{ color: "error", count: "Clients", text: Intl.NumberFormat().format(dashboardData.account.count_account_receivable) }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/sales-on-credit-collected'}>
                    <DetailedStatisticsCard
                      title="Paid Account Receivable"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faHandHoldingDollar} /> }}
                      percentage={{ color: "error", count: "Total: TZS", text: Intl.NumberFormat().format(dashboardData.account.paid_account_receivable) }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/account-payable'}>
                    <DetailedStatisticsCard
                      title="Account Payable"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faShareAlt} /> }}
                      percentage={{ color: "error", count: "Total: TZS", text: Intl.NumberFormat().format(dashboardData.account.account_payable) }}
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <Link to={'/paid-debts'}>
                    <DetailedStatisticsCard
                      title="Paid Payable Debts"
                      icon={{ color: "success", component: <FontAwesomeIcon icon={faDiagramSuccessor} /> }}
                      percentage={{ color: "error", count: "Total: TZS", text: Intl.NumberFormat().format(dashboardData.account.paid_account_payable) }}
                    />
                  </Link>
                </Grid>

              </Grid>
            </Card>
          </ArgonBox>
          <Footer />
        </>
      )}
    </DashboardLayout>
  );
}

export default AccountsDashboard;
