import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import { modalSettings } from "constants/ModalSettins";
import { FormGroup } from "@mui/material";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";

function PaidDebts() {
  const [apiData, setApiDistricts] = useState({ rows: [], total_amount: 0 })
  const [apiDataSearch, setApiDistrictsSearch] = useState({ rows: [], total_amount: 0 })
  const [apiLoading, setApiLoading] = useState(false)
  const [endloading, setEndLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [hasPrev, setHasPrev] = useState(false)
  const [hasNext, setHasNext] = useState(false)

  const completeRowsData = apiDataSearch.rows.length > 0 ? apiDataSearch : apiData

  const DistrictsData = {
    columns: [
      { name: "sn", align: "left" },
      { name: "amount", align: "left" },
      { name: "description", align: "left" },
      { name: "done_by", align: "left" },
      { name: "status", align: "left" },
      { name: "created_at", align: "center" },
    ],

    rows: completeRowsData.rows.map((item, idx) => {
      const data = {
        sn: <p style={{ padding: 10 }}>{++idx}</p>,
        amount: <ArgonTypography variant="button" fontWeight="medium">TZS {Intl.NumberFormat().format(item.amount)}</ArgonTypography>,
        description: item.description,
        done_by: item.done_by,
        status: <ArgonButton color={item.status === "UNPAID" ? "warning" : "success"} size="small">{item.status}</ArgonButton>,
        created_at: item.created_at,
      }
      return data
    }),
  };

  const { columns, rows } = DistrictsData;

  const getApiData = () => {
    const startData = localStorage.getItem("Account_Payable_data_async_")
    if (startData) {
      setApiDistricts(JSON.parse(startData))
      setApiLoading(false)
      setEndLoading(false)
    }

    const url = `${API.url}get-all-account-payable?page=${page}&status=${encode("PAID")}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setHasNext(data.has_next)
        setHasPrev(data.has_prev)
        localStorage.setItem("Account_Payable_data_async_", JSON.stringify(data.data))

        const finalData = localStorage.getItem("Account_Payable_data_async_")
        setApiDistricts(JSON.parse(finalData))

        setApiLoading(false)
        setEndLoading(false)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, [page]);

  const searchRegionFunction = (search) => {
    const url = `${API.url}get-all-account-payable-by-search?search=${encode(search)}&status=${encode("PAID")}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiDistrictsSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  // const handleScroll = (event) => {
  //   const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
  //   const scrollEndY = layoutMeasurement.height + contentOffset.y;
  //   const contentHeight = contentSize.height;

  //   if (scrollEndY >= contentHeight - 50) {
  //     getApiData();
  //   }
  // };

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search by Description..."
                    onChange={(e) => {
                      searchRegionFunction(e.target.value)
                    }}
                  />
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                  <ArgonBox p={2}>
                    <ArgonTypography fontWeight="medium">Total Amount (PAID): TZS {Intl.NumberFormat().format(completeRowsData.total_amount)}/=</ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
                <ArgonBox display="flex" p={3}>
                  {hasPrev && (
                    <ArgonButton
                      color="primary"
                      size="small"
                      onClick={() => {
                        setEndLoading(true)
                        setTimeout(() => {
                          if (page <= 1) {
                            setPage(1)
                            setEndLoading(false)
                          } else {
                            setPage(page - 1)
                          }
                        }, 1000);
                      }}
                    >
                      &laquo; prev
                    </ArgonButton>
                  )}

                  {hasNext && (
                    <ArgonButton
                      style={{ marginLeft: 10 }}
                      color="primary"
                      size="small"
                      onClick={() => {
                        setEndLoading(true)
                        setTimeout(() => {
                          setPage(page + 1)
                        }, 1000);
                      }}
                    >
                      next &raquo;
                    </ArgonButton>
                  )}
                </ArgonBox>
                {endloading && (<Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />)}
              </Card>
            </ArgonBox>
          </ArgonBox>

          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default PaidDebts;
