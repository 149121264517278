import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import { encode } from 'base-64';
import swal from "sweetalert";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import API from "Api/Api";

// Image
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg";

function Illustration() {
  const [rememberMe, setRememberMe] = useState(false);
  const [loginId, setLoginId] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const navigate = useNavigate()

  const userLoginFunction = () => {
    if (loginId === "" || password === "") {
      swal("Opps!", "Please fill in ID and Password!", "warning");
    } else {
      setIsLoading(true)
      setTimeout(() => {
        const formData = new FormData()
        formData.append("user_id", encode(loginId))
        formData.append("password", encode(password))
        fetch(`${API.url}admin-login?key=${API.key}`, { method: "POST", body: formData })
          .then(res => res.json())
          .then(data => {
            setIsLoading(false)
            if (data.result === "SUCCESS") {
              localStorage.setItem("user_id", data.user_id)
              localStorage.setItem("username", data.username)
              localStorage.setItem("role", data.role)
              localStorage.setItem("photo", data.photo)
              localStorage.setItem("region", data.region)
              localStorage.setItem("district", data.district)
              localStorage.setItem("ward", data.ward)
              // navigate(`/dashboard`)
              window.open(`/dashboard`, `_self`)
            } else {
              swal("Opps!", data.message, "warning");
            }
          })
          .catch(err => {
            setIsLoading(false)
            swal("Opps!", err.message, "warning");
          })
      }, 1000);
    }
  }

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your ID and password to sign in"
      illustration={{
        image: bgImage,
        title: '"Attention is the new currency"',
        description:
          "The more effortless the writing looks, the more effort the writer actually put into the process.",
      }}
    >

      <ArgonBox component="form" role="form">
        <ArgonBox mb={2}>
          <ArgonInput onChange={(e) => setLoginId(e.target.value)} type="text" placeholder="ID" size="large" value={loginId} />
        </ArgonBox>
        <ArgonBox mb={2}>
          <ArgonInput type="password" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Password" size="large" />
        </ArgonBox>
        <ArgonBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <ArgonTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox mt={4} mb={1}>
          {isLoading === true ? (
            <Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />
          ) : (
            <ArgonButton onClick={() => userLoginFunction()} color="info" size="large" fullWidth>
              Sign In
            </ArgonButton>
          )}
        </ArgonBox>
        <ArgonBox mt={3} textAlign="center">
          <ArgonTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <ArgonTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
            >
              Sign up
            </ArgonTypography>
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
    </IllustrationLayout>
  );
}

export default Illustration;
