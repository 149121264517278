import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import { modalSettings } from "constants/ModalSettins";
import { FormGroup } from "@mui/material";
import ArgonInput from "components/ArgonInput";
import swal from "sweetalert";
import { encode } from 'base-64';
import API from "Api/Api";
import Loader from "react-js-loader";
import { AppColor } from "constants/AppColors";
import MainLoading from "Helpers/MainLoading";
import { useNavigate } from "react-router-dom";

function ExpensesTypes() {
  const [registerModal, setRegisterModal] = useState(false)
  const [name, setName] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [apiRegions, setApiRegions] = useState([])
  const [apiRegionsSearch, setApiRegionsSearch] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [selectedUpdate, setSelectUpdate] = useState("")
  const [updateModal, setUpdateModal] = useState(false)

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const completeRowsData = apiRegionsSearch.length > 0 ? apiRegionsSearch : apiRegions

  const regionsData = {
    columns: [
      { name: "sn", align: "left" },
      { name: "name", align: "left" },
      { name: "registered_by", align: "left" },
      { name: "created_at", align: "center" },
      { name: "action", align: "center" },
    ],

    rows: completeRowsData.map((item, idx) => {
      const data = {
        sn: <p style={{ padding: 10 }}>{++idx}</p>,
        name: <ArgonBox display="flex" alignItems="center" px={1} py={0.5}>
          <ArgonBox display="flex" flexDirection="column">
            <ArgonTypography variant="button" fontWeight="medium">
              {`${item.name}`}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>,
        registered_by: item.registered_by,
        created_at: item.created_at,

        action: (
          <>
            <ArgonButton
              color="info"
              size="small"
              onClick={() => {
                setSelectUpdate(item.id)
                setName(item.name)
                setUpdateModal(true)
              }}
            >
              Edit
            </ArgonButton>
            <ArgonButton style={{ marginLeft: 5 }} size="small" color="warning" onClick={() => deleteMaterialsFunction(item.id)} >delete</ArgonButton>
          </>
        ),
      }
      return data
    }),
  };

  const { columns, rows } = regionsData;

  const getApiData = () => {
    const startData = localStorage.getItem("Expenses_Types_data_async")
    if (startData) {
      setApiRegions(JSON.parse(startData))
      setApiLoading(false)
    }

    const url = `${API.url}get-all-expenses-types?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiLoading(false)
        localStorage.setItem("Expenses_Types_data_async", JSON.stringify(data.data))
        const finalData = localStorage.getItem("Expenses_Types_data_async")
        setApiRegions(JSON.parse(finalData))
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  useEffect(() => {
    setApiLoading(true)
    getApiData()
  }, []);

  const deleteMaterialsFunction = (region_id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteProductFunctionComplete(region_id)
        } else {
          swal("Your data is safe!");
        }
      });
  }

  const deleteProductFunctionComplete = (region_id) => {
    setApiLoading(true)
    const url = `${API.url}delete-expense-type/${encode(region_id)}?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(res => {
        setApiLoading(false)
        getApiData()
        swal(res.result, res.message, "success");
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  const registerDataFunction = () => {
    if (name === "") {
      swal("Opps!", "Please fill all required informations", "warning");
    } else {
      setIsLoading(true)
      setTimeout(() => {
        const formData = new FormData()
        formData.append("registered_by", encode(localStorage.getItem("user_id")))
        formData.append("name", encode(name))
        fetch(`${API.url}register-expense-type?key=${API.key}`, { method: "POST", body: formData })
          .then(res => res.json())
          .then(res => {
            getApiData()
            setRegisterModal(false)
            setIsLoading(false)
            if (res.result === "SUCCESS") {
              clearHooks()
              swal(res.result, res.message, "success")
            } else {
              swal(res.result, res.message, "warning")
            }
          })
          .catch(err => {
            setIsLoading(false)
            swal("Opps!", err.message, "warning");
          })
      }, 1000);
    }
  }

  const clearHooks = () => {
    setName("")
  }

  const updateRegionFunction = () => {
    if (name === "") {
      swal("Opps!", "Please fill all required informations", "warning");
    } else {
      setIsLoading(true)
      setTimeout(() => {
        const formData = new FormData()
        formData.append("id", encode(selectedUpdate))
        formData.append("name", encode(name))
        fetch(`${API.url}update-expense-type?key=${API.key}`, { method: "PUT", body: formData })
          .then(res => res.json())
          .then(res => {
            getApiData()
            setUpdateModal(false)
            clearHooks()
            setIsLoading(false)
            if (res.result === "SUCCESS") {
              setName("")
              swal(res.result, res.message, "success")
            } else {
              swal(res.result, res.message, "warning")
            }
          })
          .catch(err => {
            setIsLoading(false)
            swal("Opps!", err.message, "warning");
          })
      }, 1000);
    }
  }

  const searchRegionFunction = (search) => {
    const url = `${API.url}get-all-expenses-types-by-search?search=${encode(search)}&key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setApiRegionsSearch(data.data)
      })
      .catch(err => {
        setApiLoading(false)
        swal("Opps!", err.message, "warning");
      })
  }

  return (
    <>
      {apiLoading === true ? (<MainLoading />) : (
        <DashboardLayout>
          <DashboardNavbar />
          <ArgonBox py={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <ArgonTypography variant="h6">Expenses Types</ArgonTypography>
                  <ArgonButton onClick={() => setRegisterModal(true)} color="info" size="small">
                    Add Expenses Types
                  </ArgonButton>
                </ArgonBox>

                <ArgonBox p={3}>
                  <ArgonInput
                    placeholder="Search Here..."
                    onChange={(e) => {
                      searchRegionFunction(e.target.value)
                    }}
                  />
                </ArgonBox>

                <ArgonBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </ArgonBox>
              </Card>
            </ArgonBox>
          </ArgonBox>

          <Modal
            isOpen={registerModal}
            style={modalSettings}
            contentLabel="Register Expenses Types"
          >
            <div style={{ float: "right" }}>
              <ArgonButton color="warning" onClick={() => setRegisterModal(false)}>close</ArgonButton>
            </div>
            <br />
            <br />
            <h2>Register Expenses Types</h2>
            <FormGroup>
              <ArgonTypography variant="button" color="text" fontWeight="medium">Name</ArgonTypography>
              <ArgonInput autoFocus onChange={(e) => setName(e.target.value)} value={name} placeholder="Enter Expenses Types Name" />
            </FormGroup>
            <br />
            {isLoading === true ? (
              <Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />
            ) : (
              <ArgonButton onClick={() => registerDataFunction()} color="primary">Register</ArgonButton>
            )}
          </Modal>

          {/* UPDATE MODAL */}
          <Modal
            isOpen={updateModal}
            style={modalSettings}
            contentLabel="Update Expenses Types"
          >
            <div style={{ float: "right" }}>
              <ArgonButton
                color="warning"
                onClick={() => {
                  clearHooks()
                  setUpdateModal(false)
                }}
              >
                close
              </ArgonButton>
            </div>
            <br />
            <br />
            <h2>Update Expenses Types</h2>
            <FormGroup>
              <ArgonTypography variant="button" color="text" fontWeight="medium">Name</ArgonTypography>
              <ArgonInput autoFocus onChange={(e) => setName(e.target.value)} value={name} placeholder="Enter Expenses Types Name" />
            </FormGroup>
            <br />
            {isLoading === true ? (
              <Loader type="bubble-scale" bgColor={AppColor} color={AppColor} size={100} />
            ) : (
              <ArgonButton onClick={() => updateRegionFunction()} color="primary">Update</ArgonButton>
            )}
          </Modal>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default ExpensesTypes;
