import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
// Argon Dashboard 2 MUI base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import Slider from "layouts/dashboard/components/Slider";

// Data
import { useEffect, useState } from "react";
import API from "Api/Api";
import MainLoading from "Helpers/MainLoading";
import { todayDate } from "constants/StaticDatas";
import { currentMonth } from "constants/StaticDatas";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";

function Default() {
  const [dashboardData, setDashboardData] = useState(null)
  const [graphData, setGraphData] = useState(null)

  const userToken = localStorage.getItem("user_id")
  const navigate = useNavigate()
  if (!userToken) {
    navigate("/authentication/sign-in")
  }

  const getApiData = () => {
    const getData = localStorage.getItem("Dashboard_data_async_New")
    const completeData = JSON.parse(getData)
    if (completeData) {
      const data = {
        labels: completeData.yeary_sales.months,
        datasets: [
          {
            label: "Items",
            color: "info",
            data: completeData.yeary_sales.sales,
          },
        ],
      };
      setGraphData(data)
      setDashboardData(completeData)
    } else {
      const data = {
        labels: [],
        datasets: [
          {
            label: "Items",
            color: "info",
            data: [],
          },
        ],
      };
      setGraphData(data)
    }

    const url = `${API.url}get-dashboard-data?key=${API.key}`
    fetch(url)
      .then(res => res.json())
      .then(res => {
        localStorage.setItem("Dashboard_data_async_New", JSON.stringify(res.data))
        const getData = localStorage.getItem("Dashboard_data_async_New")
        const completeData = JSON.parse(getData)
        const data = {
          labels: completeData.yeary_sales.months,
          datasets: [
            {
              label: "Items",
              color: "info",
              data: completeData.yeary_sales.sales,
            },
          ],
        };
        setGraphData(data)
        setDashboardData(completeData)
      })
      .catch(err => { })
  }

  useEffect(() => {
    getApiData()
  }, []);

  const { size } = typography;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <>{!dashboardData ? (<MainLoading />) : (
        <>
          <ArgonBox py={3}>
            <Card style={{ padding: 10, boxShadow: `1px 4px 2px 4px lightgrey`, marginBottom: 20 }}>
              <Grid container spacing={1} mb={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <DetailedStatisticsCard
                    title="today's sales"
                    count={`TZS ${Intl.NumberFormat().format(dashboardData.total_daily_sales)}`}
                    icon={{ color: "info", component: <i className="ni ni-money-coins" /> }}
                    percentage={{
                      color: "success", count: "", text: (
                        <ArgonBox>
                          <ArgonBox display="flex" flexDirection="column">
                            {dashboardData.daily_summary.map((item, key) =>
                              <ArgonTypography key={key} variant="button" color="text" fontWeight="medium">{item.name}: {Intl.NumberFormat().format(item.quantity)} PCS</ArgonTypography>
                            )}
                          </ArgonBox>
                          <hr />
                          <ArgonTypography variant="button" color="text" fontWeight="medium">Date: {todayDate}</ArgonTypography>
                        </ArgonBox>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <DetailedStatisticsCard
                    title="Monthly sales"
                    count={`TZS ${Intl.NumberFormat().format(dashboardData.total_monthly_sales)}`}
                    icon={{ color: "error", component: <i className="ni ni-world" /> }}
                    percentage={{
                      color: "success", count: "", text: (
                        <ArgonBox>
                          <ArgonBox display="flex" flexDirection="column">
                            {dashboardData.monthly_summary.map((item, key) =>
                              <ArgonTypography key={key} variant="button" color="text" fontWeight="medium">{item.name}: {Intl.NumberFormat().format(item.quantity)} PCS</ArgonTypography>
                            )}
                          </ArgonBox>
                          <hr />
                          <ArgonTypography variant="button" color="text" fontWeight="medium">{currentMonth}</ArgonTypography>
                        </ArgonBox>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <DetailedStatisticsCard
                    title="daily expenses"
                    count={`TZS: ${Intl.NumberFormat().format(dashboardData.daily_expenses)}`}
                    icon={{ color: "success", component: <i className="ni ni-paper-diploma" /> }}
                    percentage={{ color: "error", count: "", text: "Daily Expenses" }}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <DetailedStatisticsCard
                    title="monthly expenses"
                    count={`TZS: ${Intl.NumberFormat().format(dashboardData.monthly_expenses)}`}
                    icon={{ color: "success", component: <i className="ni ni-paper-diploma" /> }}
                    percentage={{ color: "error", count: "", text: "Monthly Expenses" }}
                  />
                </Grid>
              </Grid>
            </Card>
            <Card style={{ padding: 10, boxShadow: `1px 4px 2px 4px lightgrey`, marginBottom: 20 }}>
              <Grid container spacing={3} mb={2}>
                <Grid item xs={2} lg={7}>
                  {graphData && (
                    <GradientLineChart
                      title="Sales Overview"
                      description={
                        <ArgonBox display="flex" alignItems="center">
                          <ArgonBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                          </ArgonBox>
                          <ArgonTypography variant="button" color="text" fontWeight="medium">
                            {/* 4% more{" "} */}
                            <ArgonTypography variant="button" color="text" fontWeight="regular">
                              in {new Date().getFullYear()}
                            </ArgonTypography>
                          </ArgonTypography>
                        </ArgonBox>
                      }
                      chart={graphData}
                    />
                  )}
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Slider />
                </Grid>
              </Grid>
            </Card>
            {/* <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <SalesTable title="Sales by Country" rows={salesTableData} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CategoriesList title="categories" categories={categoriesListData} />
          </Grid>
        </Grid> */}
          </ArgonBox>
          <Footer />
        </>
      )}</>
    </DashboardLayout>
  );
}

export default Default;
